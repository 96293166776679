import React from 'react'

import { SearchInput } from 'components/common/SearchInput'
import { StatusSelector } from 'components/common/StatusSelector'
import { DateRangePicker } from 'components/common/withTable/DateRangePicker'
import { ORDER_STATUSES } from 'constants/orderStatus'
import { styled, s } from 'lib/styled'

const Root = styled.div(
  s(
    'flex flex-row px-4 py-3 items-center border-0 border-b-1 border-solid border-grey-lighter'
  ),
  {
    'z-index': 2,
  }
)

const statusOptions = [
  { label: 'All Orders', value: '' },
  { label: 'Confirmed Order', value: ORDER_STATUSES.CONFIRMED },
  { label: 'Cancelled Order', value: ORDER_STATUSES.CANCELLED },
]

function Filters({
  filterQuery,
  filterOrders,
  dateRange,
  filterDate,
  filterStatus,
  filterStatusOrders,
}) {
  return (
    <Root>
      <SearchInput
        value={filterQuery}
        onChange={filterOrders}
        placeholder="Search by id or client"
      />
      <DateRangePicker
        style={s('ml-3')}
        dateRange={dateRange}
        filterDate={filterDate}
      />
      <StatusSelector
        style={s('ml-3')}
        statusOptions={statusOptions}
        value={filterStatus}
        onChange={filterStatusOrders}
      />
    </Root>
  )
}

export { Filters }
