import { isEmpty, isNil } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { Availabilities } from './Availabilities'
import { ManageListingStatus } from './ManageListingStatus'
import { MediaTab } from './MediaTab'
import { OverviewTab } from './OverviewTab'
import { RejectModal } from './RejectModal'

import { Spinner } from 'components/common/Spinner'
import { WizardEditing } from 'components/common/WizardEditing'
import { WizardProgress } from 'components/common/WizardProgress'
import { GENDERS } from 'constants/genders'
import { Wizard } from 'lib/formik-wizard'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import {
  inspectedListing$,
  isInspectingListing$,
  isUpdatingListingStatus$,
  isUpdatingListing$,
  listingModule,
} from 'modules/listing'
import { convertFromHtml, calcCharscount } from 'utils/convertSlateData'
import { generateInitialMedia } from 'utils/generateInitialMedia'
import { populateAvailabilityList } from 'utils/populateAvailabilityList'

const Root = styled.div(s('p-2 flex-1 flex flex-column bg-white rounded-lg shadow-sm'))

const Title = styled.div(
  s('flex-1 py-4 text-center font-medium text-base text-primary')
)

const ConnectedListingDetails = connect(() => ({
  inspectedListing: inspectedListing$,
  isInspectingListing: isInspectingListing$,
  isUpdatingListingStatus: isUpdatingListingStatus$,
  isUpdatingListing: isUpdatingListing$,
}))(ListingDetails)

function ListingDetails({
  inspectedListing,
  isInspectingListing,
  isUpdatingListingStatus,
  isUpdatingListing,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [action, setAction] = useState('')

  if (isNil(inspectedListing)) {
    return <Redirect to="/listings" />
  }

  const {
    id,
    listingType,
    title,
    status,
    categories,
    keywords,
    itemsToBring,
    itemsToWear,
    genders,
    description,
    availabilities,
  } = inspectedListing

  const [initialPhotos] = generateInitialMedia(inspectedListing)

  function handleSuspendOrReject(actionType) {
    setShowModal(true)
    setAction(actionType)
  }

  async function updateStatus(actionType, reason) {
    await listingModule.updateListingStatus(null, id, actionType, reason)
    setShowModal(false)
  }

  return (
    <Root>
      <Wizard
        isLoading={isInspectingListing || isNil(availabilities)}
        loadingComponent={<Spinner style={s('mt-12')} />}
        formProps={{
          style: s('flex-1 flex flex-column', { overflow: 'hidden' }),
        }}
        basePath={`/listings/${id}`}
        enableReinitialize
        initialValues={{
          listingType,

          overview: {
            description: {
              text: convertFromHtml(description),
              charsCount: calcCharscount(convertFromHtml(description)),
              new: false,
            },
            categories,
            keywords,
            itemsToBring,
            itemsToWear,
            genders: isEmpty(genders) ? [GENDERS.UNSPECIFIED] : genders,
          },
          media: {
            photos: initialPhotos,
          },

          availabilities: populateAvailabilityList(listingType, availabilities),

          selectedIndex: 0,
        }}
      >
        <ManageListingStatus
          title={title}
          status={status}
          updateStatus={updateStatus}
          isUpdatingListingStatus={isUpdatingListingStatus}
          isUpdatingListing={isUpdatingListing}
          handleSuspendOrReject={handleSuspendOrReject}
          setAction={setAction}
        />
        <Row
          style={s('justify-between flex-1', {
            overflow: 'hidden',
          })}
        >
          <Col
            span={17}
            style={s('flex flex-column h-full', {
              overflow: 'hidden',
            })}
          >
            <WizardProgress isEditing={isEditing} />

            <OverviewTab
              listingType={listingType}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              id={id}
            />

            <MediaTab
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              id={id}
              initialPhotos={initialPhotos}
            />
            <WizardEditing isEditing={isEditing} setIsEditing={setIsEditing} />
          </Col>
          <Col
            span={7}
            style={s('flex flex-column h-full', {
              overflow: 'hidden',
            })}
          >
            <Row style={s('ml-2 border-solid border-0 border-b-1 border-primary')}>
              <Col>
                <Title>Venues</Title>
              </Col>
            </Row>
            <Availabilities listingType={listingType} />
          </Col>
        </Row>

        <RejectModal
          actionType={action}
          showModal={showModal}
          setShowModal={setShowModal}
          updateStatus={updateStatus}
        />
      </Wizard>
    </Root>
  )
}

export { ConnectedListingDetails as ListingDetails }
