import { prop, isNotEmpty } from '@soltalabs/ramda-extra'
import { useCombobox } from 'downshift'
import { useField } from 'formik'
import React, { useEffect } from 'react'

import { styled, s } from 'lib/styled'
import { useGeocoding } from 'utils/hooks/useGeocoding'

const Root = styled.div(s('relative'), ({ renderShadow }) =>
  renderShadow ? s('shadow-sm') : {}
)
const Label = styled.label(
  s('inline-block uppercase tracking-wide text-xs text-gray-600 font-light'),
  ({ labelPosition }) => (labelPosition === 'inline' ? s('pr-2') : s('pb-2'))
)
const InputContainer = styled.div(
  s(
    'w-full bg-gray-200 text-sm text-black border-0 border-b-2 border-solid border-gray-500 rounded-lg px-3 py-2'
  ),
  ({ isAttachedToSuggestions }) =>
    isAttachedToSuggestions ? s('rounded-b-none border-gray-400') : {}
)

const Input = styled.input(
  s('bg-transparent p-0 m-0 w-full border-0'),
  ({ readOnly }) =>
    readOnly ? s('text-gray-700') : { '&::placeholder': s('text-black') }
)

const Suggestions = styled.ul(
  s(
    'absolute z-1 bg-gray-200 p-0 py-1 m-0 w-full border-0 border-b-2 border-solid border-gray-500 rounded-b-lg'
  ),
  {
    listStyleType: 'none',
    overflow: 'hidden',
  },
  ({ isVisible }) => (isVisible ? s('block') : s('hidden'))
)

const Item = styled.li(
  s('px-3 py-2'),
  {
    cursor: 'pointer',
    '&:hover': s('bg-gray-400'),
  },
  ({ isHighlighted }) => (isHighlighted ? s('bg-gray-400') : {})
)

const ErrorMessage = styled.div(s('static mt-2 text-error text-sm'))

const service = new google.maps.places.PlacesService(
  document.createElement('div', {
    width: 0,
    height: 0,
  })
)

function AddressField({
  name,
  id,
  type = 'text',
  label,
  readOnly,
  placeholder,
  ...props
}) {
  const { results, forwardGeocode, resetResults } = useGeocoding()
  const hasResults = isNotEmpty(results)

  const [
    { onChange: onFieldChange, onBlur: onFieldBlur, multiple },
    { touched, error },
    { setTouched: setFieldTouched, setError },
  ] = useField({
    name,
    id,
    type,
    ...props,
  })

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    reset,
  } = useCombobox({
    labelId: name,
    inputId: name || id,
    items: results,
    itemToString: prop('description'),

    onStateChange: ({ type: stateChangeType, inputValue: query }) => {
      const { InputChange, InputBlur } = useCombobox.stateChangeTypes

      const handler = {
        [InputChange]: () => {
          forwardGeocode(query)

          onFieldChange({ target: { value: undefined, name } })
        },
        [InputBlur]: resetResults,
      }[stateChangeType]

      if (handler) {
        handler()
      }
    },

    onSelectedItemChange: async ({ selectedItem }) => {
      try {
        const place = await new Promise((resolve, reject) => {
          service.getDetails(
            {
              placeId: selectedItem.place_id,
            },
            (place, status) => {
              if (
                status !== google.maps.places.PlacesServiceStatus.OK ||
                !place?.address_components ||
                place.address_components.length === 0
              ) {
                reject(
                  new Error('Error occurred after selecting address. Please try again')
                )
              }
              resolve(place)
            }
          )
        })

        onFieldChange({ target: { value: place, name } })
      } catch (e) {
        setError(e.message)
      }

      resetResults()
    },
  })

  function handleBlur(...args) {
    setFieldTouched(true)

    onFieldBlur(...args)
  }

  useEffect(() => {
    if (readOnly) {
      reset()
    }
  }, [readOnly])

  return (
    <Root>
      <Label {...getLabelProps()}>{label}</Label>

      <InputContainer isAttachedToSuggestions={hasResults} {...getComboboxProps()}>
        <Input
          multiple={multiple}
          placeholder={placeholder}
          {...getInputProps({ onBlur: handleBlur })}
          {...props}
          readOnly={readOnly}
        />
      </InputContainer>

      {!hasResults && touched && error && <ErrorMessage>{error}</ErrorMessage>}

      <Suggestions isVisible={hasResults} {...getMenuProps()}>
        {results.map((place, index) => (
          <Item
            isHighlighted={highlightedIndex === index}
            key={prop('place_id', place)}
            {...getItemProps({ item: place, index })}
          >
            {prop('description', place)}
          </Item>
        ))}
      </Suggestions>
    </Root>
  )
}
export { AddressField }
