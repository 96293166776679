import { format, addMinutes, isAfter, isBefore, isEqual, addDays } from 'date-fns'
import { isEmpty } from 'ramda'
import React from 'react'

import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { deepMemo } from 'utils/react'

const Text = styled.span(s('w-full text-sm text-black py-2'), {
  lineHeight: 1.5,
})

function safelyParse(startAtList) {
  const sortedStartAtList = startAtList
    .slice()
    .sort((a, b) => isBefore(new Date(a), new Date(b)))

  const next90Days = addDays(Date.now(), 90)

  const filteredSortedStartAtList = sortedStartAtList.filter(
    (startAt) =>
      (isAfter(new Date(startAt), Date.now()) ||
        isEqual(new Date(startAt), Date.now())) &&
      isBefore(new Date(startAt), next90Days)
  )

  if (isEmpty(filteredSortedStartAtList)) {
    return 'No upcoming date'
  }

  const upcomingFiveEventsDate = []
  const upcomingEventsLimit = Math.min(5, filteredSortedStartAtList.length)

  for (let index = 0; index < upcomingEventsLimit; index += 1) {
    const upcomingEvent = filteredSortedStartAtList[index]

    const scheduledDate = format(new Date(upcomingEvent), 'eee dd MMM yyyy')
    const startTime = format(new Date(upcomingEvent), 'h:mm aaa').toLowerCase()
    const endTime = format(
      addMinutes(new Date(upcomingEvent), 0),
      'h:mm aaa'
    ).toLowerCase()

    upcomingFiveEventsDate.push({
      scheduledDate,
      scheduledTime: `${startTime} to ${endTime}`,
    })
  }

  return upcomingFiveEventsDate
}

const Memoized = deepMemo(DateAndTime)

function DateAndTime({ startAtList }) {
  const upcomingEventsDateList = safelyParse(startAtList)

  if (!Array.isArray(upcomingEventsDateList)) {
    return (
      <Row>
        <Col style={s('px-3')}>
          <Text style={s('pr-3 text-gray-600')}>{upcomingEventsDateList}</Text>
        </Col>
      </Row>
    )
  }

  return (
    <>
      {upcomingEventsDateList.map(({ scheduledDate, scheduledTime }) => (
        <Row key={scheduledDate} style={s('mb-2')}>
          <Col style={s('px-3')}>
            <Text style={s('pr-3')}>{scheduledDate}</Text>
            <Text>{scheduledTime}</Text>
          </Col>
        </Row>
      ))}
    </>
  )
}

export { Memoized as DateAndTime }
