import { Formik, Form } from 'formik'
import React from 'react'
import Modal from 'react-modal'
import * as Validator from 'yup'

import { Button as ButtonBase } from 'components/common/Button'
import { TextField } from 'components/common/TextField'
import { Row, Col, Container } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { categoryModule } from 'modules/category'

const initialValues = {
  name: '',
}

const { createCategory, fetchCategories } = categoryModule

const { string } = Validator

const validationSchema = Validator.object({
  name: string().required('A category must have a name'),
})

const Root = styled.div(s('text-black bg-white rounded-lg p-6 pb-2 w-24'))

const ConfirmButton = styled(ButtonBase)(s('w-100 mx-1 bg-gray-300'))

const CancelButton = styled(ButtonBase)(
  s('w-5 mr-1 bg-red-lightest border-red-lighter'),
  {
    '&:active': s('bg-red-lighter border-red-light'),
  }
)

const Title = styled.h2(s('text-xl font-semibold mt-2 mb-4 px-2'))

Modal.setAppElement('#root')

function CreateCategoryFormModal({ isOpen, setIsOpen }) {
  async function handleSubmit(data) {
    createCategory(data)
    fetchCategories()
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Container>
        <Row justify="center">
          <Col>
            <Root>
              <Formik
                style={s('flex-1 flex flex-row  ', { overflow: 'hidden' })}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form style={s('flex flex-column', { overflow: 'hidden' })}>
                  <Row>
                    <Col span={24}>
                      <Title>Create Category</Title>
                      <TextField name={'name'} />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} style={s('mt-5')}>
                    <Col span={12}>
                      <CancelButton
                        type="button"
                        variant="secondary"
                        onClick={() => {
                          setIsOpen(false)
                        }}
                      >
                        Cancel
                      </CancelButton>
                    </Col>
                    <Col span={12}>
                      <ConfirmButton>Create Category</ConfirmButton>
                    </Col>
                  </Row>
                </Form>
              </Formik>
            </Root>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}
export { CreateCategoryFormModal }
