import { path, isNil, isNilOrEmpty, prop } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { Formik as FormProvider, Form } from 'formik'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { AccountDetails } from './AccountDetails'
import { BusinessDetails } from './BusinessDetails'
import { ManageAccount } from './ManageAccount'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import {
  providerModule,
  inspectedEntity$,
  inspectedProvider$,
  isInspectingProvider$,
} from 'modules/provider'

const Container = styled.div(
  s('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)
const Scrollable = styled.div(
  s('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)
const Details = styled(Form)(s('flex-1 flex flex-column'))

const ConnectedProviderDetails = connect(() => ({
  isInspectingProvider: isInspectingProvider$,
  inspectedEntity: inspectedEntity$,
  inspectedProvider: inspectedProvider$,
}))(ProviderDetails)

const { updateProvider } = providerModule

function ProviderDetails({ isInspectingProvider, inspectedProvider }) {
  const [isEditing, setIsEditing] = useState(false)

  if (isNil(inspectedProvider)) {
    return <Redirect to="/providers" />
  }

  const {
    id,
    status,
    listingApprovalRequired,
    owner,
    logo,
    name,
    businessRegistrationNumber,
    taxNumber,
    tradingName,
    phone,
    website,
    businessSummary,
    address,
    email: businessEmail,
  } = inspectedProvider

  const { avatar, email, firstName, lastName, mobile } = owner

  const facebook = path(['socialNetworks', 'facebook'], inspectedProvider)
  const twitter = path(['socialNetworks', 'twitter'], inspectedProvider)
  const instagram = path(['socialNetworks', 'instagram'], inspectedProvider)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = async ({ editedToggles }) => {
    await updateProvider(id, editedToggles)
    setIsEditing(false)
  }

  return (
    <Container>
      <Loader
        loading={isInspectingProvider}
        renderPlaceholder={
          <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
        }
      >
        <Scrollable>
          <Row>
            <Col span={24}>
              <ManageAccount
                id={id}
                status={status}
                listingApprovalRequired={listingApprovalRequired}
                onEdit={handleEdit}
                onSave={handleSave}
                isEditing={isEditing}
                owner={owner}
              />

              <FormProvider
                enableReinitialize
                initialValues={{
                  email,
                  firstName,
                  lastName,
                  mobile,
                  facebook,
                  twitter,
                  instagram,
                  name,
                  businessRegistrationNumber,
                  taxNumber,
                  tradingName: isNil(tradingName) ? name : tradingName,
                  phone,
                  website,
                  businessSummary,
                  address: prop('formattedAddress', address),
                  businessEmail,
                  notGSTRegistered: isNilOrEmpty(taxNumber),
                }}
              >
                <Details>
                  <AccountDetails avatar={avatar} status={status} />

                  <BusinessDetails logo={logo} />
                </Details>
              </FormProvider>
            </Col>
          </Row>
        </Scrollable>
      </Loader>
    </Container>
  )
}

export { ConnectedProviderDetails as ProviderDetails }
