import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { CategoriesTable } from './CategoriesTable'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('flex-1 flex flex-row p-6'))

function CategoriesDashboard() {
  return (
    <Root>
      <Switch>
        <Route exact path="/categories" component={CategoriesTable} />
        <Redirect to="/categories" />
      </Switch>
    </Root>
  )
}

export { CategoriesDashboard }
