/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'

import { StyledCell } from '../../common/withTable/StyledCell'

import { s } from 'lib/styled'

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Booking number',
        accessor: 'reference',
        disableSortBy: true,
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '20%',
      },
      {
        Header: 'Title',
        accessor: 'title',
        disableSortBy: true,
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '25%',
      },
      {
        Header: 'Venue',
        accessor: 'venue',
        disableSortBy: true,
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '25%',
      },
      {
        Header: 'Purchased on',
        accessor: 'createdAt',
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '20%',
      },
      {
        Header: () => <StyledCell style={s('ml-a')} value="Amount" />,
        accessor: 'total',
        Cell: (row) => <StyledCell style={s('text-right')} value={row.value} />,
        width: '10%',
      },
    ],
    []
  )
}
