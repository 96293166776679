import './polyfills'
import './globals'
import { ThemeProvider } from 'emotion-theming'
import { createBrowserHistory as createHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import App from 'App'
import { CONFIG } from 'config'
import { IdentityProvider } from 'lib/solta-id-react'
import { breakpoints } from 'styles/breakpoints'

const history = createHistory()

function onRedirectCallback(appState) {
  history.push(
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  )
}

const { IDENTITY } = CONFIG

ReactDOM.render(
  <IdentityProvider
    domain={IDENTITY.DOMAIN}
    clientId={IDENTITY.CLIENT_ID}
    redirectUrl={IDENTITY.REDIRECT_URL}
    audience={IDENTITY.AUDIENCE}
    onRedirectCallback={onRedirectCallback}
  >
    <Router history={history}>
      <ThemeProvider theme={{ breakpoints }}>
        <App />
      </ThemeProvider>
    </Router>
  </IdentityProvider>,
  document.getElementById('root')
)
