import React from 'react'

import { Button as ButtonBase } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { LISTING_STATUSES } from 'constants/listingStatus'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const statusMapper = {
  [LISTING_STATUSES.AWAITING_APPROVAL]: { type: 'Waiting Approval', color: 'grey' },
  [LISTING_STATUSES.LISTED]: { type: 'Approved', color: 'green' },
  [LISTING_STATUSES.SUSPENDED]: { type: 'Suspended', color: 'orange' },
  [LISTING_STATUSES.REJECTED]: { type: 'Rejected', color: 'red' },
}
const Root = styled.section(s('pt-4 mt-4'))
const Title = styled.div(s('text-black text-base font-normal'))

const StatusTag = styled.div(
  s('text-black py-1 bg-grey-light px-3 rounded-lg text-sm'),
  ({ color }) => s(`bg-${color}-lightest border-${color}-lighter`),
  {
    opacity: 0.8,
  }
)

const ApproveButton = styled(ButtonBase)(
  s('w-5 mx-1 bg-green-lightest border-green-lighter'),
  {
    '&:not(:disabled)': { cursor: 'pointer' },
    '&:active': s('bg-green-lighter border-green-light'),
    '&:disabled': s('bg-gray-200 border-gray-500 text-black'),
  }
)
const RejectButton = styled(ButtonBase)(
  s('w-5 mx-1 bg-red-lightest border-red-lighter'),
  {
    '&:not(:disabled)': { cursor: 'pointer' },
    '&:active': s('bg-red-lighter border-red-light'),
    '&:disabled': s('bg-gray-200 border-gray-500 text-black'),
  }
)
const SuspendButton = styled(ButtonBase)(
  s('w-5 mx-1 bg-orange-lightest border-orange-lighter'),
  {
    '&:not(:disabled)': { cursor: 'pointer' },
    '&:active': s('bg-orange-lighter border-orange-light'),
    '&:disabled': s('bg-gray-200 border-gray-500 text-black'),
  }
)

// eslint-disable-next-line complexity
function ManageListingStatus({
  title,
  status,
  updateStatus,
  isUpdatingListingStatus,
  isUpdatingListing,
  handleSuspendOrReject,
}) {
  return (
    <Root>
      <Row gutter={[16, 16]} style={s('justify-between')}>
        <Col>
          <Row style={s('items-center')}>
            <Col style={s('mr-2')}>
              <Title>{title}</Title>
            </Col>

            <Col>
              <StatusTag color={statusMapper[status].color}>
                {statusMapper[status].type}
              </StatusTag>
            </Col>
          </Row>
        </Col>
        {statusMapper[status].type !== 'Rejected' && (
          <Col>
            <Row>
              {(isUpdatingListingStatus || isUpdatingListing) && (
                <Spinner style={s('mr-2')} size={10} thickness={3} color="#FFFFFF" />
              )}
              <ApproveButton
                type="button"
                disabled={statusMapper[status].type === 'Approved'}
                onClick={() => updateStatus('approve')}
              >
                Approve
              </ApproveButton>
              {statusMapper[status].type === 'Waiting Approval' ? (
                <RejectButton
                  type="button"
                  disabled={statusMapper[status].type === 'Rejected'}
                  onClick={() => handleSuspendOrReject('reject')}
                >
                  Reject
                </RejectButton>
              ) : (
                <SuspendButton
                  type="button"
                  disabled={statusMapper[status].type === 'Suspended'}
                  onClick={() => handleSuspendOrReject('suspend')}
                >
                  Suspend
                </SuspendButton>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </Root>
  )
}

export { ManageListingStatus }
