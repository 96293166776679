import { map, prop, path, pathOr } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { categoryModule } from './module'

const { state$, effectState$ } = categoryModule

const categoryEntities$ = select(state$, (state) => prop('entities', state))

const allCategoryEntities$ = select(state$, (state) => prop('allEntities', state))

const filterQuery$ = select(state$, (state) => prop('filterQuery', state))

const paging$ = select(state$, (state) => prop('paging', state))

const order$ = select(state$, (state) => prop('order', state))

const limit$ = select(state$, (state) => prop('limit', state))

const fetchCategoryStatus$ = select(
  categoryEntities$,
  effectState$,
  (clientEntities, effectState) =>
    pathOr('pending', [clientEntities, 'status'], effectState)
)

const isLoadingCategories$ = select(
  fetchCategoryStatus$,
  (status) => status === 'pending'
)

const categoryList$ = select(order$, categoryEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

export {
  categoryList$,
  filterQuery$,
  paging$,
  limit$,
  isLoadingCategories$,
  allCategoryEntities$,
}
