import React from 'react'

import { ClientDetails } from './ClientDetails'
import { ClientsTable } from './ClientsTable'

import { styled, s } from 'lib/styled'

const Container = styled.div(s('flex-1 flex flex-row p-6'))

const ClientsDashboard = ({ displayTable = true }) => (
  <Container>{displayTable ? <ClientsTable /> : <ClientDetails />}</Container>
)

export { ClientsDashboard }
