import { map, prop, path, pathOr } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { orderModule } from './module'

const { state$, effectState$ } = orderModule

const orderEntities$ = select(state$, (state) => prop('entities', state))
const order$ = select(state$, (state) => prop('order', state))
const inspectedEntity$ = select(state$, (state) => prop('inspectedEntity', state))

const orderList$ = select(order$, orderEntities$, (order, enities) =>
  map((id) => path([id], enities), order)
)
const inspectedOrder$ = select(inspectedEntity$, orderEntities$, (id, entities) =>
  prop(id, entities)
)

const sortBy$ = select(state$, (state) => prop('sortBy', state))

const sortOrder$ = select(state$, (state) => prop('sortOrder', state))

const filterQuery$ = select(state$, (state) => prop('filterQuery', state))

const filterStatus$ = select(state$, (state) => prop('status', state))

const dateRange$ = select(state$, (state) => prop('dateRange', state))

const paging$ = select(state$, (state) => prop('paging', state))

const limit$ = select(state$, (state) => prop('limit', state))

const fetchOrdersStatus$ = select(
  orderEntities$,
  effectState$,
  (orderEntities, effectState) =>
    pathOr('pending', [orderEntities, 'status'], effectState)
)

const inspectOrderStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectOrder', inspectedEntity, 'status'], effectState)
)

const isLoadingOrders$ = select(fetchOrdersStatus$, (status) => status === 'pending')

const isInspectingOrder$ = select(inspectOrderStatus$, (status) => status === 'pending')

export {
  orderList$,
  filterQuery$,
  filterStatus$,
  sortBy$,
  sortOrder$,
  paging$,
  dateRange$,
  limit$,
  isLoadingOrders$,
  inspectedEntity$,
  inspectedOrder$,
  isInspectingOrder$,
}
