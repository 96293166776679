import { map, prop, path, pathOr } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { clientModule } from './module'

const { state$, effectState$ } = clientModule

const clientEntities$ = select(state$, (state) => prop('entities', state))
const order$ = select(state$, (state) => prop('order', state))
const inspectedEntity$ = select(state$, (state) => prop('inspectedEntity', state))

const clientList$ = select(order$, clientEntities$, (order, enities) =>
  map((id) => path([id], enities), order)
)
const inspectedClient$ = select(inspectedEntity$, clientEntities$, (id, entities) =>
  prop(id, entities)
)

const sortBy$ = select(state$, (state) => prop('sortBy', state))

const sortOrder$ = select(state$, (state) => prop('sortOrder', state))

const filterQuery$ = select(state$, (state) => prop('filterQuery', state))

const paging$ = select(state$, (state) => prop('paging', state))

const limit$ = select(state$, (state) => prop('limit', state))

const fetchClientsStatus$ = select(
  clientEntities$,
  effectState$,
  (clientEntities, effectState) =>
    pathOr('pending', [clientEntities, 'status'], effectState)
)

const inspectClientStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectClient', inspectedEntity, 'status'], effectState)
)

const isLoadingClients$ = select(fetchClientsStatus$, (status) => status === 'pending')

const isInspectingClient$ = select(
  inspectClientStatus$,
  (status) => status === 'pending'
)

export {
  clientList$,
  filterQuery$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
  isLoadingClients$,
  inspectedEntity$,
  inspectedClient$,
  isInspectingClient$,
}
