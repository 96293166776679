/* eslint-disable complexity */
import { isNil } from 'ramda'
import React from 'react'

import { LISTING_STATUSES } from 'constants/listingStatus'
import { LISTING_TYPES } from 'modules/listing'
import { safelyParse } from 'utils/safelyParse'

const totalRows = 20

const statusMapper = {
  [LISTING_STATUSES.AWAITING_APPROVAL]: 'Waiting Approval',
  [LISTING_STATUSES.LISTED]: 'Approved',
  [LISTING_STATUSES.SUSPENDED]: 'Suspended',
  [LISTING_STATUSES.REJECTED]: 'Rejected',
}

const listingTypesMapper = {
  [LISTING_TYPES.valueOf(LISTING_TYPES.ONLINE)]: 'Online',
  [LISTING_TYPES.valueOf(LISTING_TYPES.PHYSICAL)]: 'Physical',
  [LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY)]: 'Community',
}

const createEmptyRows = (fetchedList) => {
  for (let index = fetchedList.length; index < totalRows; index += 1) {
    fetchedList.push({
      id: null,
      title: null,
      createdAt: null,
      providerName: null,
      status: null,
    })
  }
}

export const Rows = (list) => {
  return React.useMemo(() => {
    const listingsList = list.slice()
    if (listingsList.length < totalRows) {
      createEmptyRows(listingsList)
    }
    return listingsList.map(
      ({ id, title, listingType, createdAt, providerName, status }) => ({
        id,
        title,
        listingType: listingTypesMapper[listingType],
        createdAt: isNil(createdAt) ? null : safelyParse(createdAt),
        providerName,
        status: statusMapper[status],
      })
    )
  }, [list])
}
