import { format } from 'date-fns'
import { isNil } from 'ramda'

export function safelyParse(dateOfBirth, { includeTime = false } = {}) {
  if (isNil(dateOfBirth)) {
    return 'Unspecified'
  }

  return includeTime
    ? format(new Date(dateOfBirth), 'dd MMM yyyy h:mm aaa')
    : format(new Date(dateOfBirth), 'dd MMM yyyy')
}
