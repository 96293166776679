import { isNil } from 'ramda'
import React from 'react'

import { safelyParse } from 'utils/safelyParse'

const totalRows = 20

const createEmptyRows = (fetchedList) => {
  for (let index = fetchedList.length; index < totalRows; index += 1) {
    fetchedList.push({
      id: null,
      title: null,
      createdAt: null,
    })
  }
}

export const Rows = (list = []) =>
  React.useMemo(() => {
    if (list.length < totalRows) {
      createEmptyRows(list)
    }
    return list.map(({ id, name, createdAt }) => ({
      id,
      name,
      createdAt: isNil(createdAt) ? null : safelyParse(createdAt),
    }))
  }, [list])
