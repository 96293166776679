import { isNil } from 'ramda'
import React from 'react'

import { styled, s } from 'lib/styled'

const Container = styled.div(
  s(
    'flex flex-row items-center justify-center rounded-full border-blue-400 bg-transparent',
    {
      overflow: 'hidden',
    }
  ),
  ({ size, showPlaceholder, src }) => ({
    width: size,
    height: size,
    borderStyle: showPlaceholder ? 'solid' : 'none',
    borderWidth: 5,
    backgroundColor: showPlaceholder ? 'white' : '#CCC',
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& > i': {
      display: showPlaceholder ? 'block' : 'none',
    },
  })
)

const Placeholder = styled.i(s('self-end text-blue-400'), ({ size }) => ({
  fontSize: size,
}))

function Avatar({ size = 54, placeholderSize = 40, src, ...props }) {
  return (
    <Container showPlaceholder={isNil(src)} src={src} size={size} {...props}>
      <Placeholder className="fas fa-user" size={placeholderSize} />
    </Container>
  )
}

export { Avatar }
