import { connect } from '@soltalabs/stateless'
import React, { useLayoutEffect } from 'react'

import { Table } from '../../common/withTable'

import { Columns } from './Columns'
import { Filters } from './Filters'
import { Rows } from './Rows'

import { styled, s } from 'lib/styled'
import {
  listingModule,
  listingList$,
  isLoadingListings$,
  inspectedListing$,
  filterQuery$,
  filterStatus$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
  dateRange$,
} from 'modules/listing'

const Container = styled.div(s('flex-1 flex flex-column bg-white rounded-lg shadow-sm'))

const ConnectedListingsTable = connect(() => ({
  listingList: listingList$,
  isLoadingListings: isLoadingListings$,
  filterQuery: filterQuery$,
  inspectedListing: inspectedListing$,
  filterStatus: filterStatus$,
  sortBy: sortBy$,
  sortOrder: sortOrder$,
  paging: paging$,
  limit: limit$,
  dateRange: dateRange$,
}))(ListingsTable)

const {
  fetchListings,
  inspectListing,
  filterListings,
  filterStatusListings,
  sortListings,
  turnPage,
  filterDate,
} = listingModule

function ListingsTable({
  listingList,
  filterQuery,
  filterStatus,
  sortBy,
  sortOrder,
  paging,
  limit,
  dateRange,
}) {
  useLayoutEffect(() => {
    fetchListings()
  }, [])

  const columns = Columns()
  const rows = Rows(listingList)
  const { startRow, next } = paging

  return (
    <Container>
      <Filters
        filterQuery={filterQuery}
        filterListings={filterListings}
        filterStatus={filterStatus}
        filterStatusListings={filterStatusListings}
        dateRange={dateRange}
        filterDate={filterDate}
      />
      <Table
        columns={columns}
        data={rows}
        onClick={inspectListing}
        sortBy={sortBy}
        sortOrder={sortOrder}
        startRow={startRow}
        next={next}
        limit={limit}
        onSort={sortListings}
        turnPage={turnPage}
        domain={'listings'}
      />
    </Container>
  )
}

export { ConnectedListingsTable as ListingsTable }
