/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'

import { StyledCell } from '../../common/withTable/StyledCell'

import { s } from 'lib/styled'

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Client Name',
        accessor: 'name',
        id: 'accountDetails.name',
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '20%',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '20%',
        disableSortBy: true,
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '15%',
        disableSortBy: true,
      },
      {
        Header: () => <StyledCell style={s('ml-a')} value="Child Profile" />,
        accessor: 'childProfile',
        Cell: (row) => <StyledCell style={s('text-right')} value={row.value} />,
        width: '10%',
        disableSortBy: true,
      },
      {
        Header: 'Registered on',
        accessor: 'registeredAt',
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '15%',
        disableSortBy: true,
      },
      {
        Header: () => <StyledCell style={s('ml-a')} value="Total Orders" />,
        accessor: 'totalOrders',
        Cell: (row) => <StyledCell style={s('text-right')} value={row.value} />,
        width: '10%',
      },
      {
        Header: () => <StyledCell style={s('ml-a')} value="Total Amount" />,
        accessor: 'totalAmount',
        Cell: (row) => <StyledCell style={s('text-right')} value={row.value} />,
        width: '10%',
      },
    ],
    []
  )
}
