import { createModule } from '@soltalabs/stateless'

import { AuthService } from './service'

const INITIAL_STATE = Object.freeze({
  account: undefined,
})

const checkAccountAdminStatus = (module) => async () => {
  const account = await AuthService.readAccount()

  module.setState({ account })
}

const authModule = createModule({
  name: 'auth',
  initialState: INITIAL_STATE,
  decorators: {
    checkAccountAdminStatus,
  },
})

export { authModule }
