/* eslint-disable camelcase */

const PRODUCT_STATUSES = {
  DRAFT: 'product_draft',
  AWAITING_APPROVAL: 'product_awaiting_approval',
  LISTED: 'product_listed',
  EXPIRED: 'product_expired',
  SUSPENDED: 'product_suspended',
  REJECTED: 'product_rejected',
}

const PRODUCT_STATUS_LABELS = {
  product_draft: 'Draft',
  product_listed: 'Active',
}

const PRODUCT_CATEGORIES = {
  ADVENTURES: 'product_adventures',
  BACKPACKING: 'product_backpacking',
  COUPLES: 'product_couples',
  CRUISING: 'product_cruising',
  DIVING: 'product_diving',
  FAMILY: 'product_family',
  GOLF: 'product_golf',
  LUXURY: 'product_luxury',
  NATURE: 'product_nature',
  SAILING: 'product_sailing',
  SUSTAINABILITY: 'product_sustainability',
  SNORKELING: 'product_snorkeling',
  SURFING: 'product_surfing',
  VOLUNTEERING: 'product_volunteering',
  WEDDINGS: 'product_weddings',
}

const PRODUCT_CATEGORY_LABELS = {
  product_adventures: 'Adventures',
  product_backpacking: 'Backpacking',
  product_couples: 'Couples',
  product_cruising: 'Cruises',
  product_diving: 'Diving',
  product_family: 'Family',
  product_golf: 'Golf',
  product_luxury: 'Luxury',
  product_nature: 'Nature',
  product_sailing: 'Sailing',
  product_sustainability: 'Sustainability',
  product_snorkeling: 'Snorkeling',
  product_surfing: 'Surfing',
  product_volunteering: 'Volunteer',
  product_weddings: 'Wedding',
}

export {
  PRODUCT_STATUSES,
  PRODUCT_STATUS_LABELS,
  PRODUCT_CATEGORIES,
  PRODUCT_CATEGORY_LABELS,
}
