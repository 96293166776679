/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from 'react'

import { StyledCell } from '../../common/withTable/StyledCell'

import { s } from 'lib/styled'

export const Columns = () =>
  React.useMemo(
    () => [
      {
        Header: 'Category Name',
        accessor: 'name',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '50%',
      },
      {
        Header: 'Created On',
        accessor: 'createdAt',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '50%',
      },
    ],
    []
  )
