import { path } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import React from 'react'

import { Button as ButtonBase } from 'components/common/Button'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { Spinner } from 'components/common/Spinner'
import { useWizard } from 'lib/formik-wizard'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { deepMemo } from 'utils/react'

const Root = styled.div('flex flex-row')
const Button = styled(ButtonBase)(s('w-5'))

const Memoized = deepMemo(WizardEditing)

function WizardEditing({ isEditing, setIsEditing, ...props }) {
  const { isSubmitting, submitForm, resetForm } = useFormContext()

  const { submitContext } = useWizard()

  const hasSubmissionError = submitContext.status === 'error'

  return (
    <Root {...props}>
      <Row justify="between" style={s('mt-8 mb-3')}>
        <Col style={s('flex flex-row-reverse items-center ml-a')}>
          {isEditing ? (
            <Row>
              <Col span={12}>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    resetForm()
                    setIsEditing(false)
                  }}
                >
                  Cancel
                </Button>
              </Col>

              <Col span={12} style={s('text-right')}>
                <Button
                  type="button"
                  onClick={() => {
                    submitForm()
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          ) : (
            <Button
              type="button"
              onClick={() => {
                setIsEditing(true)
              }}
            >
              Edit
            </Button>
          )}

          {isSubmitting && <Spinner size={20} thickness={3} style={s('mr-3')} />}
          {!isSubmitting && hasSubmissionError && (
            <ErrorMessage style={s('mr-3')}>
              {path(['error', 'message'], submitContext)}
            </ErrorMessage>
          )}
        </Col>
      </Row>
    </Root>
  )
}

export { Memoized as WizardEditing }
