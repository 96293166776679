import { isNil } from 'ramda'
import React from 'react'

import { PROVIDER_STATUSES } from 'constants/providerStatus'
import { safelyParse } from 'utils/safelyParse'

const totalRows = 20
const statusMapper = {
  [PROVIDER_STATUSES.ACTIVE]: 'Active',
  [PROVIDER_STATUSES.SUSPENDED]: 'Suspended',
  [PROVIDER_STATUSES.BANNED]: 'Banned',
}

const isActiveActivities = (activeActivities) =>
  isNil(activeActivities) ? 0 : activeActivities

const createEmptyRows = (originList) => {
  for (let index = originList.length; index < totalRows; index += 1) {
    originList.push({
      id: null,
      name: null,
      website: null,
      phone: null,
      owner: {
        fullName: null,
        mobile: null,
      },
      joinedAt: null,
      activeActivities: null,
      status: null,
    })
  }
}

export const Rows = (list) => {
  return React.useMemo(() => {
    const providerList = list.slice()
    if (providerList.length < totalRows) {
      createEmptyRows(providerList)
    }
    return providerList.map(
      ({
        id,
        name,
        website,
        phone,
        joinedAt,
        status,
        activeActivities,
        serviceFee,
        owner,
      }) => ({
        id,
        name,
        website,
        phone,
        owner: owner?.fullName,
        mobile: owner?.mobile,
        joinedAt: isNil(joinedAt) ? null : safelyParse(joinedAt),
        activeActivities: isNil(id) ? null : isActiveActivities(activeActivities),
        serviceFee: isNil(serviceFee) ? null : serviceFee,
        status: isNil(joinedAt) ? null : statusMapper[status],
      })
    )
  }, [list])
}
