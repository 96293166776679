/* eslint-disable complexity */
import { isNil, isNotNil, prop } from '@soltalabs/ramda-extra'
import React, { useCallback, useState } from 'react'

import { Button } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { Row } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { UploadService } from 'modules/upload'
import { generateFilePreview } from 'utils/generateFilePreview'
import { useFileUpload } from 'utils/hooks'

const Root = styled.div(s(''))

const Container = styled.div(
  s(
    'rounded-lg mt-4 relative flex flex-row items-center justify-center h-24 bg-gray-200 border-2 border-dashed border-gray-500'
  ),
  {
    cursor: 'pointer',
    overflow: 'hidden',
  },
  ({ hideBorder }) => (hideBorder ? s('border-0') : {})
)
const DropBox = styled.div(s('max-h-full max-w-full text-center'), {
  objectFit: 'contain',
})
const Preview = styled.img(s(' h-full w-full'), {
  objectFit: 'contain',
})
const ReuploadButton = styled(Button)(
  s(
    'flex-1 tracking-wide rounded-lg p-0 text-sm bg-gray-200 border-0 border-solid border-b-2 border-gray-500 font-medium text-black'
  ),
  {
    cursor: 'pointer',
    '&:disabled': s('border-gray-200 text-gray-600', {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    }),
  }
)
const Label = styled.label(s('flex flex-1 justify-center px-2 py-3'))
const OpaqueOverlay = styled.div(
  s('absolute h-full w-full flex items-center justify-center bg-black'),
  {
    backgroundColor: '#00000088',
  }
)
const ReuploadOverlay = styled(OpaqueOverlay)(
  s('bg-black flex-column text-center text-white text-semibold justify-end'),
  {
    opacity: 1,
    backgroundColor: '#00000088',
  }
)

function PhotoUpload({
  uploadedPhotos,
  inspectedPhoto,
  setInspectedPhoto,
  name,
  acceptType,
  maxSize,
  onChange,
  disabled,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false)

  const { getRootProps, getInputProps } = useFileUpload({
    accept: acceptType,
    maxSize,
    disabled,
    noKeyboard: true,
    onDropAccepted: useCallback(upload),
  })

  const uploadFile = prop('uploadFile', inspectedPhoto)
  const preview = generateFilePreview(uploadFile, inspectedPhoto)

  async function upload(file) {
    setIsLoading(true)
    setInspectedPhoto({ uploadId: undefined, uploadFile: undefined, tags: undefined })
    const uploadFiles = await Promise.all(
      file.map(async (singleFile) => {
        const { id } = await UploadService.uploadFile(singleFile)
        return { uploadId: id, uploadFile: singleFile, tags: [] }
      })
    )
    onChange({ target: { value: [...uploadedPhotos, ...uploadFiles], name } })
    setInspectedPhoto({
      uploadId: uploadFiles[uploadFiles.length - 1].uploadId,
      uploadFile: uploadFiles[uploadFiles.length - 1].uploadFile,
      tags: undefined,
    })
    setIsLoading(false)
  }

  return (
    <Root>
      <Container
        {...getRootProps()}
        hideBorder={isLoading || isNotNil(preview)}
        {...props}
      >
        {isLoading && (
          <OpaqueOverlay>
            <Spinner size={36} thickness={4} />
          </OpaqueOverlay>
        )}

        {isNil(preview) ? (
          <DropBox>
            {'Drag & drop a file here, or click to select a file'}
            <br />
            {'PDF, JPEG, PNG are allowed'}
          </DropBox>
        ) : (
          <ReuploadOverlay>
            <Preview src={preview} />
          </ReuploadOverlay>
        )}

        <input
          id={name}
          {...getInputProps()}
          onClick={(event) => {
            const { target } = event
            target.value = null
          }}
        />
      </Container>
      <Row style={s('text-center justify-between mt-2')}>
        <ReuploadButton type="button" disabled={disabled || isNil(preview)}>
          <Label htmlFor={name}>Upload new file</Label>
        </ReuploadButton>
      </Row>
    </Root>
  )
}

export { PhotoUpload }
