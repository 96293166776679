import { isNilOrEmpty } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'

import { DEFAULT_PLATFORM_SERVICE_FEE } from './constants'
import { ServiceFeeService } from './service'

const INITIAL_STATE = Object.freeze({
  platformServiceFeePercentage: DEFAULT_PLATFORM_SERVICE_FEE,
})

const fetchPlatformServiceFee = (module) => async () => {
  const rate = await ServiceFeeService.getPlatformServiceFee()
  if (isNilOrEmpty(rate)) {
    module.setState({
      platformServiceFeePercentage: DEFAULT_PLATFORM_SERVICE_FEE,
    })
  }
  module.setState({
    platformServiceFeePercentage: rate,
  })
}

const createPlatformServiceFee =
  (module) => async (_, platformServiceFeePercentage) => {
    const rate = await ServiceFeeService.createPlatformServiceFee(
      platformServiceFeePercentage
    )
    if (isNilOrEmpty(rate)) {
      module.setState({
        platformServiceFeePercentage: DEFAULT_PLATFORM_SERVICE_FEE,
      })
    }
    module.setState({
      platformServiceFeePercentage: rate,
    })
  }

const serviceFeeModule = createModule({
  name: 'serviceFee',
  initialState: INITIAL_STATE,
  decorators: {
    fetchPlatformServiceFee,
    createPlatformServiceFee,
  },
})

export { serviceFeeModule }
