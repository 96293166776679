import { connect } from '@soltalabs/stateless'
import React, { useLayoutEffect } from 'react'

import { Table } from '../../common/withTable'

import { Columns } from './Columns'
import { Filters } from './Filters'
import { Rows } from './Rows'

import { styled, s } from 'lib/styled'
import {
  orderModule,
  orderList$,
  isLoadingOrders$,
  inspectedOrder$,
  filterQuery$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
  dateRange$,
  filterStatus$,
} from 'modules/order'

const Container = styled.div(s('flex-1 flex flex-column bg-white rounded-lg shadow-sm'))

const ConnectedOrdersTable = connect(() => ({
  orderList: orderList$,
  isLoadingOrders: isLoadingOrders$,
  inspectedOrder: inspectedOrder$,
  filterQuery: filterQuery$,
  sortBy: sortBy$,
  sortOrder: sortOrder$,
  paging: paging$,
  limit: limit$,
  dateRange: dateRange$,
  filterStatus: filterStatus$,
}))(OrdersTable)

const {
  fetchOrders,
  inspectOrder,
  filterOrders,
  sortOrders,
  turnPage,
  filterDate,
  filterStatusOrders,
} = orderModule

function OrdersTable({
  orderList,
  filterQuery,
  sortBy,
  sortOrder,
  paging,
  limit,
  dateRange,
  filterStatus,
}) {
  useLayoutEffect(() => {
    fetchOrders()
  }, [])

  const columns = Columns()
  const rows = Rows(orderList)
  const { startRow, next } = paging

  return (
    <Container>
      <Filters
        filterQuery={filterQuery}
        filterOrders={filterOrders}
        dateRange={dateRange}
        filterDate={filterDate}
        filterStatus={filterStatus}
        filterStatusOrders={filterStatusOrders}
      />
      <Table
        columns={columns}
        data={rows}
        onClick={inspectOrder}
        sortBy={sortBy}
        sortOrder={sortOrder}
        startRow={startRow}
        next={next}
        limit={limit}
        onSort={sortOrders}
        turnPage={turnPage}
        domain={'orders'}
      />
    </Container>
  )
}

export { ConnectedOrdersTable as OrdersTable }
