import { prop, filter } from '@soltalabs/ramda-extra'

import { MEDIA_TYPES } from 'modules/listing'

function filterMedia(inspectedListing, type) {
  return filter(
    ({ mediaType }) => mediaType === MEDIA_TYPES.valueOf(type),
    prop('media', inspectedListing) || []
  )
}

function generateInitialMedia(inspectedListing) {
  const initialPhotos = filterMedia(inspectedListing, MEDIA_TYPES.PHOTO)

  const initialVideo = prop(0, filterMedia(inspectedListing, MEDIA_TYPES.VIDEO))

  const initialBrochure = prop(0, filterMedia(inspectedListing, MEDIA_TYPES.BROCHURE))

  const initialTermsAndConditions = prop(
    0,
    filterMedia(inspectedListing, MEDIA_TYPES.TERMS_CONDITIONS)
  )

  const initialCancellation = prop(
    0,
    filterMedia(inspectedListing, MEDIA_TYPES.CANCELLATION)
  )

  const initialRiskAssessment = prop(
    0,
    filterMedia(inspectedListing, MEDIA_TYPES.RISK_ASSESSMENT)
  )

  return [
    initialPhotos,
    initialVideo,
    initialBrochure,
    initialTermsAndConditions,
    initialCancellation,
    initialRiskAssessment,
  ]
}

export { generateInitialMedia }
