import { isNotNil } from '@soltalabs/ramda-extra'
import React from 'react'

import { Button as ButtonBase } from 'components/common/Button'
import { styled, s } from 'lib/styled'

const Container = styled.div(s('justify-end flex m-4 mr-0 items-center'))
const Button = styled(ButtonBase)(s('mx-1 justify-end flex'))

export function CustomPagination({
  next,
  startRow,
  limit,
  goToNextPage,
  goToPreviousPage,
}) {
  const canNextPage = isNotNil(next)
  const canPreviousPage = next !== limit && (isNotNil(next) || isNotNil(startRow))

  return (
    <Container>
      <Button onClick={() => goToPreviousPage()} disabled={!canPreviousPage}>
        {'<'}
      </Button>
      <Button onClick={() => goToNextPage()} disabled={!canNextPage}>
        {'>'}
      </Button>
    </Container>
  )
}
