/* eslint-disable no-param-reassign */
import React from 'react'

export function Leaf({ attributes, children, leaf }) {
  if (leaf.bold) {
    children = leaf.text ? (
      <strong>
        <span data-slate-string style={{ fontWeight: 700 }}>
          {leaf.text}
        </span>
      </strong>
    ) : (
      <span>{children}</span>
    )
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}
