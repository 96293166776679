import { map, prop, path, pathOr } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { orderHistoryModule } from './module'

const { state$, effectState$ } = orderHistoryModule

const orderHistoryEntities$ = select(state$, (state) => prop('entities', state))
const order$ = select(state$, (state) => prop('order', state))

const orderHistoryList$ = select(order$, orderHistoryEntities$, (order, enities) =>
  map((id) => path([id], enities), order)
)
const sortBy$ = select(state$, (state) => prop('sortBy', state))

const sortOrder$ = select(state$, (state) => prop('sortOrder', state))

const filterQuery$ = select(state$, (state) => prop('filterQuery', state))

const paging$ = select(state$, (state) => prop('paging', state))

const limit$ = select(state$, (state) => prop('limit', state))

const fetchOrderHistoryStatus$ = select(
  orderHistoryEntities$,
  effectState$,
  (orderHistoryEntities, effectState) =>
    pathOr('pending', [orderHistoryEntities, 'status'], effectState)
)

const isLoadingOrderHistory$ = select(
  fetchOrderHistoryStatus$,
  (status) => status === 'pending'
)

export {
  orderHistoryList$,
  filterQuery$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
  isLoadingOrderHistory$,
}
