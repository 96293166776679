/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'

import { StyledCell } from 'components/common/withTable/StyledCell'
import { s } from 'lib/styled'

const statusMapper = {
  Active: 'bg-green-lightest',
  Suspended: 'bg-orange-lightest saturate-2',
  Banned: 'bg-red-lightest',
}

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Business Name',
        accessor: 'name',
        disableFilters: true,
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '13%',
      },
      {
        Header: 'Website',
        accessor: 'website',
        disableSortBy: true,
        Cell: (row) => (
          <StyledCell
            style={s('text-left', { overflowWrap: 'break-word' })}
            value={row.value}
          />
        ),
        width: '20%',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        disableSortBy: true,
        Cell: (row) => (
          <StyledCell
            style={s('text-left', { overflowWrap: 'break-word' })}
            value={row.value}
          />
        ),
        width: '12%',
      },
      {
        Header: 'Registered On',
        accessor: 'joinedAt',
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '11%',
      },
      {
        Header: () => <StyledCell style={s('ml-a')} value="Active Activities" />,
        accessor: 'activeActivities',
        disableSortBy: true,
        Cell: (row) => <StyledCell style={s('text-right')} value={row.value} />,
        width: '9%',
      },
      {
        Header: () => <StyledCell style={s('ml-a')} value="Service Fee" />,
        accessor: 'serviceFee',
        disableSortBy: true,
        Cell: (row) => <StyledCell style={s('text-right')} value={`${row.value}%`} />,
        width: '9%',
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        disableSortBy: true,
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '10%',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        disableSortBy: true,
        Cell: (row) => (
          <StyledCell
            style={s('text-left', { overflowWrap: 'break-word' })}
            value={row.value}
          />
        ),
        width: '12%',
      },
      {
        Header: () => <StyledCell value="Status" />,
        accessor: 'status',
        disableSortBy: true,
        width: '13%',
        textAlign: 'left',
        Cell: (row) => (
          <StyledCell
            style={s(`rounded-md inline-block px-2 py-1 ${statusMapper[row.value]}`)}
            value={row.value}
          />
        ),
      },
    ],
    []
  )
}
