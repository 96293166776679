import { prop, defaultTo, isNotNil } from '@soltalabs/ramda-extra'
import { normalize, schema } from 'normalizr'

import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { getIdentityClientInstance } from 'lib/solta-id'

const service = http.extend({
  prefixUrl: `${CONFIG.API.URL}/admin`,
  hooks: {
    beforeRequest: [
      async (request) => {
        const identityClient = await getIdentityClientInstance()
        const token = await identityClient.getTokenSilently()
        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const clientEntity = new schema.Entity('clients')

const ClientService = {
  async list({ query = '', limit = 20, next, sortBy, sortOrder }) {
    const config = {
      authorize: true,
      searchParams: {
        q: query,
        limit,
        sortBy,
        sortOrder,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await service.get('customers', config).json()
    const normalized = normalize(items, [clientEntity])
    const entities = defaultTo({}, prop('clients', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      sortOrder: paging.sortOrder,
    }
  },

  async read(id) {
    const config = {
      authorize: true,
    }
    return service.get(`customers/${id}`, config).json()
  },
}

export { ClientService }
