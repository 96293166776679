import { pluck } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import React from 'react'
import { WithContext as ReactTags } from 'react-tag-input'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('block w-full'))
const InputContainer = styled.div(
  s(
    'p-2 mt-2 border-0 border-solid border-b-2 border-gray-500 rounded-lg bg-gray-200 text-black'
  ),
  {
    '& .ReactTags__selected': s('w-full min-h-2'),
    '& .ReactTags__tagInput': s('inline-flex'),
    '& .ReactTags__tagInputField': s(
      'w-full rounded-lg h-2 m-0 border-0 ml-2 bg-gray-200'
    ),
    '& .ReactTags__tags': s('relative'),
    '& .ReactTags__tag': s(
      'inline-flex self-center border-0 border-b-2 rounded-lg border-solid py-2 px-3 mr-3 border-primary-lighter bg-primary-lightest'
    ),
    '& .ReactTags__remove': s('ml-3 text-grey-dark font-bold', {
      cursor: 'pointer',
    }),
  },
  ({ readOnly }) =>
    readOnly
      ? s('text-gray-700', {
          '& .ReactTags__tag': s('border-primary-lightest'),
        })
      : {}
)
const Label = styled.label(s('uppercase tracking-wide text-xs text-gray-600 mb-2'))
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

const Keys = {
  TAB: 9,
  ENTER: 13,
  COMMA: 188,
}

function TagInput({ name, id, label, readOnly, ...props }) {
  const [fieldProps, { touched, error }] = useField({ name, id, ...props })
  const { value, onChange } = fieldProps

  const tags = value ? value.map((tag) => ({ id: tag, text: tag })) : []
  function handleDelete(i) {
    const newTag = tags.filter((tag, index) => index !== i)
    const event = { target: { value: pluck('text', newTag), name } }
    onChange(event)
  }
  function handleAdd(tag) {
    if (tags.length === 10) {
      return
    }
    const newTag = [...tags, tag]
    const event = { target: { value: pluck('text', newTag), name } }
    onChange(event)
  }
  return (
    <Root>
      <Label>{label} (limit 10 tags)</Label>
      <InputContainer readOnly={readOnly}>
        <ReactTags
          tags={tags}
          autofocus={false}
          maxLength={20}
          allowDragDrop={false}
          handleDelete={(i) => handleDelete(i)}
          handleAddition={(tag) => handleAdd(tag)}
          delimiters={[Keys.TAB, Keys.ENTER, Keys.COMMA]}
          readOnly={readOnly}
        />
      </InputContainer>
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { TagInput }
