import { path } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import React, { useState, useCallback } from 'react'
import * as Validator from 'yup'

import { ProductOptions } from '../common/ProductForms/ProductOptions'
import { Variants } from '../common/ProductForms/Variants'

import { Wizard } from 'lib/formik-wizard'
import { styled, s } from 'lib/styled'
import { ProductDraftModule } from 'modules/product/draft'

const Scroll = styled.div(
  s('flex-1', {
    overflowX: 'hidden',
    overflowY: 'auto',
  })
)

const Root = styled.div(s('flex flex-column mt-6'))
const ErrorMessage = styled.span(s('text-red mr-a ml-a'))

const { object, number, string, array } = Validator

const noSpecialCharacters = string()
  .trim()
  .matches(/^[a-zA-Z0-9 _-]+$/g, 'No special characters')
  .required()
  .label('This')

const variantSchema = object({
  optionSet: array().of(noSpecialCharacters).required(),
  price: number().required(),
})

const optionSchema = object({
  values: array().required('Please provide at least one option value'),
})

const validationSchema = Validator.object({
  optionsAndVariants: object({
    options: array(optionSchema).required('Please provide at least one option'),
    variants: array(variantSchema).required(
      'Please Generate Variants before submission'
    ),
  }),
})

function OptionsVariantsStep() {
  const { values } = useFormContext()
  const [mustGenerate, setMustGenerate] = useState(true)
  const [showGenerationPrompt, setShowGenerationPrompt] = useState(false)

  async function handleSubmit({ draftId, optionsAndVariants }) {
    setShowGenerationPrompt(false)
    await ProductDraftModule.updateProductOptionsVariants(
      null,
      draftId,
      optionsAndVariants
    )
  }

  const validate = useCallback(async () => {
    if (mustGenerate) {
      return {
        optionsAndVariants: {
          variants: 'Please Generate Variants before submission',
        },
      }
    }
    return {}
  }, [mustGenerate])

  const setMustGenerateOverload = ({ value }) => {
    setMustGenerate(value)
  }

  return (
    <Wizard.Step
      id="optionsAndVariants"
      title="Options & Variants"
      validate={validate}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Scroll>
        <Root>
          <ProductOptions
            isEditing={true}
            setMustGenerate={setMustGenerateOverload}
            initialOptions={path(['optionsAndVariants', 'options'], values)}
          />
          <Variants isEditing={true} />
          {showGenerationPrompt && (
            <ErrorMessage>
              Please generate new variants before saving changes.
            </ErrorMessage>
          )}
        </Root>
      </Scroll>
    </Wizard.Step>
  )
}

export { OptionsVariantsStep }
