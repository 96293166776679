import { Formik as FormProvider, Form } from 'formik'
import React from 'react'

import { TextField } from '../../common/TextField'

import { Avatar } from 'components/common/Avatar'
import { styled, s } from 'lib/styled'
import { safelyParse } from 'utils/safelyParse'

const Container = styled.section(
  s(
    'flex-1 p-4 pb-8 border-0 border-r-1 border-solid border-grey-lighter pt-0 pr-8 w-2/3 text-sm'
  ),
  {
    '& input': {
      lineHeight: 'unset',
    },
  }
)
const Header = styled.div(s('flex flex-row py-6'))
const Title = styled.h2(s('m-0 text-lg font-normal'))
const Details = styled(Form)(s('flex-1 flex flex-row'))
const Column = styled.div(s('flex-1 flex flex-column'))
const Row = styled.div(
  s('flex-1 flex flex-row my-2', {
    '&:first-of-type': s('mt-0'),
    '&:last-of-type': s('mb-0'),
  })
)
const Text = styled.div(s('text-gray-600 font-bold'), { opacity: 0.6 })

function AccountDetails({ joinedAt, avatarSrc, email, firstName, lastName, mobile }) {
  return (
    <Container>
      <Header>
        <Title>Client Details</Title>
      </Header>

      <FormProvider
        enableReinitialize
        initialValues={{
          email,
          avatarSrc,
          firstName,
          lastName,
          mobile,
          joinedAt,
        }}
      >
        <Details>
          <Column style={s('items-center w-10 mr-6 mt-6', { flex: 'none' })}>
            <Avatar src={avatarSrc} size={96} placeholderSize={80} style={s('m-0')} />
          </Column>
          <Column>
            <Row>
              <TextField
                containerProps={{ style: s('w-full') }}
                readOnly={true}
                name="firstName"
                type="text"
                label="First name"
              />

              <TextField
                containerProps={{ style: s('w-full ml-4') }}
                readOnly={true}
                name="lastName"
                type="text"
                label="Last name"
              />
            </Row>
            <Row>
              <TextField
                containerProps={{ style: s('w-full') }}
                readOnly={true}
                name="mobile"
                type="text"
                label="Mobile"
              />
              <TextField
                containerProps={{ style: s('w-full ml-4') }}
                readOnly={true}
                name="email"
                type="text"
                label="E-mail"
              />
            </Row>
            <Row style={s('my-4 ml-2')}>
              <Text>Member Since: {safelyParse(joinedAt)}</Text>
            </Row>
          </Column>
        </Details>
      </FormProvider>
    </Container>
  )
}

export { AccountDetails }
