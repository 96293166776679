import React from 'react'

import { OrderDetails } from './OrderDetails'
import { OrdersTable } from './OrdersTable'

import { styled, s } from 'lib/styled'

const Container = styled.div(s('flex-1 flex flex-row p-6'))

const OrdersDashboard = ({ displayTable = true }) => (
  <Container>{displayTable ? <OrdersTable /> : <OrderDetails />}</Container>
)

export { OrdersDashboard }
