import { createModule } from '@soltalabs/stateless'

import { ProviderService } from './service'

import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  sortBy: '',
  sortOrder: '',
  status: '',
  limit: 20,
  paging: {
    startRow: undefined,
    next: undefined,
  },
  total: '',
})

const fetchProviders =
  (module) =>
  async (_, { turnPage = false, turnNext } = {}) => {
    const { filterQuery, status, limit, paging, sortBy, sortOrder } = module.getState()
    const next = calcNext(turnPage, turnNext, paging, limit)

    const {
      entities,
      order,
      next: newNext,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
      total,
    } = await ProviderService.list({
      query: filterQuery,
      next,
      sortBy,
      sortOrder,
      status,
      limit,
    })

    module.setState({
      entities,
      order,
      paging: {
        startRow: calcStartRow(newNext, limit, paging),
        next: newNext,
      },
      sortBy: newSortBy,
      sortOrder: newSortOrder,
      total,
    })
  }

const sortProviders = (module) => (sortBy, sortOrder) => {
  module.setState({
    paging: {
      startRow: undefined,
      next: undefined,
    },
    sortBy,
    sortOrder,
  })

  module.fetchProviders(null, { turnPage: false })
}

const turnPage =
  (module) =>
  ({ turnNext }) => {
    module.fetchProviders(null, { turnPage: true, turnNext })
  }

const filterStatusProviders = (module) => (status) => {
  module.setState({
    status,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

  module.fetchProviders(null, { turnPage: false })
}

const filterProviders = (module) => (query) => {
  module.setState({
    filterQuery: query,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

  module.fetchProviders(null, { turnPage: false })
}

const inspectProvider = (module) => async (id) => {
  module.setState({
    inspectedEntity: id,
  })

  const provider = await ProviderService.read(id)

  module.setState({
    entities: { [id]: provider },
  })
}

const updateProvider = (module) => async (id, body) => {
  const provider = await ProviderService.update(id, body)

  module.setState({
    entities: { [id]: provider },
  })
}

const providerModule = createModule({
  name: 'provider',
  initialState: INITIAL_STATE,
  decorators: {
    fetchProviders,
    filterProviders,
    filterStatusProviders,
    sortProviders,
    inspectProvider,
    updateProvider,
    turnPage,
  },
})

export { providerModule }
