import { pathOr, defaultTo, prop } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { authModule } from './module'

const { state$, effectState$ } = authModule

const isCheckingAdminStatus$ = select(effectState$, (state) => {
  const status = pathOr('pending')(['checkAccountAdminStatus', 'status'])(state)

  return status === 'pending'
})

const account$ = select(state$, (state) => prop('account', state))
const isAdmin$ = select(account$, (account) =>
  defaultTo(false)(prop('isPlayzAdmin')(account))
)
const fullName$ = select(account$, (account) => prop('fullName', account) || '')
const avatar$ = select(account$, (state) => prop('avatar', state))

export { isCheckingAdminStatus$, fullName$, avatar$, account$, isAdmin$ }
