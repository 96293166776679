import { createModule } from '@soltalabs/stateless'

import { CategoryService } from './service'

import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'

const INITIAL_STATE = Object.freeze({
  entities: null,
  allEntities: null,
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  sortBy: '',
  sortOrder: '',
  statuses: '',
  limit: 20,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

const fetchCategories =
  (module) =>
  async (_, { turnPage = false, turnNext } = {}) => {
    const { filterQuery, paging, statuses, limit, sortBy, sortOrder } =
      module.getState()
    const next = calcNext(turnPage, turnNext, paging, limit)
    const {
      entities,
      order,
      next: newNext,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    } = await CategoryService.list({
      query: filterQuery,
      next,
      sortBy,
      sortOrder,
      statuses,
      limit,
    })

    module.setState({
      entities,
      order,
      paging: {
        startRow: calcStartRow(newNext, limit, paging),
        next: newNext,
      },
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    })
  }

const fetchAllCategories = (module) => async () => {
  const allEntities = await CategoryService.listAll()
  module.setState({ allEntities })
}

const createCategory = (module) => async (newCategory) => {
  const category = await CategoryService.create(newCategory)
  module.setState({
    entities: { [category._id]: category },
  })
}

const categoryModule = createModule({
  name: 'category',
  initialState: INITIAL_STATE,
  decorators: { fetchCategories, fetchAllCategories, createCategory },
})

export { categoryModule }
