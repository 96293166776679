import { isNil, prop } from '@soltalabs/ramda-extra'
import React from 'react'

import { ORDER_STATUSES } from 'constants/orderStatus'
import { safelyParse } from 'utils/safelyParse'

const totalRows = 20

const createEmptyRows = (fetchedList) => {
  for (let index = fetchedList.length; index < totalRows; index += 1) {
    fetchedList.push({
      reference: null,
      listing: null,
      createdAt: null,
      providerName: null,
      accountDetails: null,
      total: null,
    })
  }
}
const statusMapper = {
  [ORDER_STATUSES.CONFIRMED]: 'Confirmed',
  [ORDER_STATUSES.CANCELLED]: 'Cancelled',
}
export const Rows = (list) => {
  return React.useMemo(() => {
    const ordersList = list.slice()
    if (ordersList.length < totalRows) {
      createEmptyRows(ordersList)
    }
    return ordersList.map(
      ({
        id,
        reference,
        listing,
        product,
        providerName,
        createdAt,
        accountDetails,
        subTotal,
        status,
      }) => ({
        id,
        reference,
        title: prop('title', listing) ?? prop('name', product),
        createdAt: isNil(createdAt) ? null : safelyParse(createdAt),
        providerName,
        client: prop('name', accountDetails),
        totalAmount: subTotal?.toFixed(2),
        status: statusMapper[status],
      })
    )
  }, [list])
}
