import { useFormikContext as useFormContext, FieldArray } from 'formik'
import React from 'react'

import { NumberField } from 'components/common/NumberField'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const VariantConfig = styled.div(s('ml-a mr-a mt-2 pt-2 pb-2 pl-2 pr-2'), {
  border: '2px solid rgba(225, 225, 225, 0.5)',
  'border-radius': '4px',
})

const VariantHeader = styled.span(s('font-bold text-lg'))

function createVariantString(variant) {
  let variantString = ''
  variantString = variant.optionSet.reduce(
    (variantString, variantValue) => `${variantString} / ${variantValue}`
  )
  return variantString
}

function Variants({ isEditing }) {
  const { values, setFieldValue } = useFormContext()

  return (
    <FieldArray
      name="variants"
      render={() => (
        <>
          {values.optionsAndVariants.variants.map((variant, variantIndex) => (
            <VariantConfig key={variantIndex}>
              <Row gutter={[16, 16]}>
                <Col>
                  <VariantHeader>{createVariantString(variant)}</VariantHeader>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={s('justify-center')}>
                <Col>
                  <NumberField
                    name={`optionsAndVariants.variants[${variantIndex}].price`}
                    label="Price"
                    type="number"
                    readOnly={!isEditing}
                    onChange={(e) => {
                      setFieldValue(
                        `optionsAndVariants.variants[${variantIndex}].price`,
                        parseFloat(e.target.value)
                      )
                    }}
                  />
                </Col>
                <Col>
                  <NumberField
                    name={`optionsAndVariants.variants[${variantIndex}].quantity`}
                    label="Quantity (If Applicable)"
                    type="number"
                    readOnly={!isEditing}
                    onChange={(e) => {
                      setFieldValue(
                        `optionsAndVariants.variants[${variantIndex}].quantity`,
                        parseFloat(e.target.value)
                      )
                    }}
                  />
                </Col>
              </Row>
            </VariantConfig>
          ))}
        </>
      )}
    />
  )
}

export { Variants }
