import { connect } from '@soltalabs/stateless'
import { isNil } from 'ramda'
import React from 'react'
import { Redirect } from 'react-router-dom'

import { OrderHistoryTable } from '../OrderHistoryTable'

import { AccountDetails } from './AccountDetails'
import { ChildProfile } from './ChildProfile'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { styled, s } from 'lib/styled'
import { inspectedEntity$, inspectedClient$, isInspectingClient$ } from 'modules/client'

const Container = styled.div(
  s('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)

const Scrollable = styled.div(
  s('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)
const Row = styled.div(
  s('flex-1 flex flex-row my-2', {
    '&:first-of-type': s('mt-0'),
    '&:last-of-type': s('mb-0'),
  })
)

const ConnectedClientDetails = connect(() => ({
  isInspectingClient: isInspectingClient$,
  inspectedEntity: inspectedEntity$,
  inspectedClient: inspectedClient$,
}))(ClientDetails)

function ClientDetails({ isInspectingClient, inspectedClient }) {
  if (isNil(inspectedClient)) {
    return <Redirect to="/clients" />
  }

  const { children, accountDetails } = inspectedClient

  const { avatar, email, firstName, lastName, mobile, registeredAt } = accountDetails

  return (
    <Container>
      <Loader
        loading={isInspectingClient}
        renderPlaceholder={
          <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
        }
      >
        <Scrollable>
          <Row />
          <Row>
            <AccountDetails
              avatarSrc={avatar}
              email={email}
              firstName={firstName}
              lastName={lastName}
              mobile={mobile}
              joinedAt={registeredAt}
            />
            <ChildProfile childProfiles={children} />
          </Row>
          <Row style={s('my-0 border-0 border-b-1 border-solid border-grey-lighter')} />
          <OrderHistoryTable />
        </Scrollable>
      </Loader>
    </Container>
  )
}

export { ConnectedClientDetails as ClientDetails }
