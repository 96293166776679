import React from 'react'

import { useWizard } from 'lib/formik-wizard'
import { styled, s, smaller } from 'lib/styled'

const Root = styled.div(s('flex flex-row'))
const Title = styled.div(
  s(
    'mb-2 flex-1 py-4 text-center font-medium text-base border-0 border-solid border-primary'
  ),
  {
    borderBottomWidth: '2px',
  },
  smaller('sm')(s('text-sm')),
  ({ isCurrentStep = false }) =>
    isCurrentStep
      ? s('border-primary text-primary')
      : {
          borderColor: '#DDD',
          color: '#DDD',
        }
)

function WizardProgress({ isEditing, ...props }) {
  const { currentStepIndex, steps, go } = useWizard()

  function handleClick(id) {
    if (!isEditing) {
      go(id)
    }
  }

  return (
    <Root {...props}>
      {steps.map(({ id, title }, stepIndex) => {
        return (
          <Title
            key={id}
            isCurrentStep={stepIndex === currentStepIndex}
            onClick={() => handleClick(id)}
          >
            {title}
          </Title>
        )
      })}
    </Root>
  )
}

export { WizardProgress }
