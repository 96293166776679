import { isNotNilOrEmpty } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import React from 'react'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('block'))
const InputContainer = styled.div(s('flex'), ({ labelPosition }) =>
  labelPosition === 'inline' ? s('flex-row items-center') : s('flex-column')
)
const Label = styled.label(
  s('uppercase tracking-wide text-xs text-gray-600'),
  ({ isVisible }) => (!isVisible ? s('hidden') : {}),
  ({ labelPosition }) => (labelPosition === 'inline' ? s('mr-2') : s('mb-2'))
)
const Input = styled.input(
  s(
    'w-full bg-gray-200 text-sm text-black border-0 border-b-2 border-gray-500 rounded-lg px-3 py-2'
  ),
  ({ readOnly }) => (readOnly ? s('text-gray-700') : {})
)
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

function TextField({
  name,
  id,
  type = 'text',
  label,
  labelPosition = 'above',
  className,
  containerProps,
  ...props
}) {
  const [fieldProps, { touched, error }] = useField({
    name,
    id,
    type,
    ...props,
  })

  return (
    <Root className={className} {...containerProps}>
      <InputContainer labelPosition={labelPosition}>
        <Label
          isVisible={isNotNilOrEmpty(label)}
          htmlFor={name || id}
          labelPosition={labelPosition}
        >
          {label}
        </Label>

        <Input {...fieldProps} {...props} />
      </InputContainer>

      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { TextField }
