import { connect } from '@soltalabs/stateless'
import React, { useLayoutEffect } from 'react'

import { Table } from '../../common/withTable'

import { Columns } from './Columns'
import { Rows } from './Rows'

import { styled, s } from 'lib/styled'
import {
  orderHistoryModule,
  orderHistoryList$,
  isLoadingOrderHistory$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
} from 'modules/client/orderHistroy'

const Container = styled.div(s('flex-1 flex flex-column bg-white'))
const TitleBar = styled.div(s('px-4 pt-8 pborder-0'), {
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const Title = styled.div(s('m-0 text-lg text-black font-normal'))

const ConnectedOrderHistoryTable = connect(() => ({
  orderHistoryList: orderHistoryList$,
  isLoadingOrderHistory: isLoadingOrderHistory$,
  sortBy: sortBy$,
  sortOrder: sortOrder$,
  paging: paging$,
  limit: limit$,
}))(OrderHistoryTable)

const { fetchOrderHistory, sortOrderHistory, turnPage } = orderHistoryModule

function OrderHistoryTable({ orderHistoryList, sortBy, sortOrder, paging, limit }) {
  useLayoutEffect(() => {
    fetchOrderHistory()
  }, [])

  const columns = Columns()
  const rows = Rows(orderHistoryList)
  const { startRow, next } = paging

  return (
    <Container>
      <TitleBar>
        <Title>Order History</Title>
      </TitleBar>
      <Table
        columns={columns}
        data={rows}
        sortBy={sortBy}
        sortOrder={sortOrder}
        limit={limit}
        onSort={sortOrderHistory}
        turnPage={turnPage}
        next={next}
        startRow={startRow}
        domain={'orderHistory'}
      />
    </Container>
  )
}

export { ConnectedOrderHistoryTable as OrderHistoryTable }
