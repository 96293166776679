import { isNilOrEmpty, prop } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import React from 'react'
import AutosizeInput from 'react-input-autosize'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('mt-2'))
const Input = styled(AutosizeInput)({
  '& input': s(
    'bg-primary-lighter border-0 text-black text-center border-0 rounded-full border-solid px-3',
    { lineHeight: 1 }
  ),
})
const Text = styled.div(
  s('py-1 text-xs'),
  {
    '&:hover': s('underline', { cursor: 'pointer' }),
  },
  ({ readOnly }) =>
    readOnly
      ? {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        }
      : {}
)

function TagInput({ name, readOnly }) {
  const [fieldProps] = useField({
    name,
  })
  const { value, onChange } = fieldProps
  function handleOnBlur() {
    if (isNilOrEmpty(prop(0, value))) {
      onChange({ target: { value: [], name } })
    }
  }

  return (
    <Root>
      {isNilOrEmpty(value) ? (
        <Text
          readOnly={readOnly}
          onMouseDown={(event) => {
            event.preventDefault()
            onChange({ target: { value: [''], name } })
          }}
        >
          Add tag
        </Text>
      ) : (
        <Input
          {...fieldProps}
          name={name}
          readOnly={readOnly}
          onBlur={() => handleOnBlur()}
          extraWidth={0}
          style={s('text-xs')}
          onChange={(e) => {
            onChange({ target: { value: [e.target.value], name } })
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
        />
      )}
    </Root>
  )
}

export { TagInput }
