import { isNotNil, defaultTo, prop } from '@soltalabs/ramda-extra'
import { normalize, schema } from 'normalizr'

import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { getIdentityClientInstance } from 'lib/solta-id'

const service = http.extend({
  prefixUrl: `${CONFIG.API.URL}/admin`,
  hooks: {
    beforeRequest: [
      async (request) => {
        const identityClient = await getIdentityClientInstance()
        const token = await identityClient.getTokenSilently()
        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const categoryEntity = new schema.Entity('categories')

const CategoryService = {
  async list({ query = '', limit = 10, next, sortBy, sortOrder, statuses }) {
    const config = {
      searchParams: {
        q: query,
        limit,
        sortBy,
        sortOrder,
        statuses,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await service.get('categories', config).json()
    const normalized = normalize(items, [categoryEntity])
    const entities = defaultTo({}, prop('categories', normalized.entities))
    const order = defaultTo([], normalized.result)
    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      sortOrder: paging.sortOrder,
    }
  },
  async listAll() {
    const { items } = await service.get('categories/all').json()
    return items
  },
  async create(data) {
    const dataToSend = { ...data }
    const config = { json: dataToSend }
    return service.post('categories', config).json()
  },
}

export { CategoryService }
