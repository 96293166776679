/* eslint-disable no-nested-ternary */
import { isNil } from '@soltalabs/ramda-extra'
import React, { useState, useEffect } from 'react'

import { ReactComponent as Asc } from 'assets/feathers/arrow-up.svg'
import { ReactComponent as Sort } from 'assets/feathers/pointing.svg'
import { ReactComponent as Desc } from 'assets/feathers/sort-down.svg'
import { styled, s } from 'lib/styled'

const Container = styled.span(s('ml-1'), ({ id }) => (id === 'status' ? s('mr-a') : {}))

export function SortIcon({ column, onSort, sortBy, sortOrder }) {
  const [isSorted, setIsSorted] = useState(false)
  const [isSortedAsc, setIsSortedAsc] = useState(false)

  useEffect(() => {
    if (sortBy === column.id) {
      setIsSorted(true)
      setIsSortedAsc(sortOrder === 'asc')
      return
    }

    setIsSorted(false)
  }, [sortBy, sortOrder])

  function handleSort() {
    if (isNil(onSort)) {
      return
    }
    if (!isSorted) {
      setIsSorted(true)
      setIsSortedAsc(true)
      onSort(column.id, 'asc')
    } else if (isSortedAsc) {
      setIsSortedAsc(false)
      onSort(column.id, 'desc')
    } else {
      setIsSorted(false)
      onSort('', '')
    }
  }

  return (
    <Container id={column.id} onClick={handleSort}>
      {isSorted ? (
        isSortedAsc ? (
          <Asc width={8} height={12} fill="#000000" />
        ) : (
          <Desc width={8} height={12} fill="#000000" />
        )
      ) : (
        <Sort width={12} height={10} fill="#000000" />
      )}
    </Container>
  )
}
