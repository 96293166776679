import { isNotNilOrEmpty } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import React from 'react'

import { styled, s } from 'lib/styled'

const Root = styled.div(s(''))
const Label = styled.label(
  s(
    'flex flex-row items-center m-0 py-2 text-xs uppercase tracking-wide font-semibold text-gray-900'
  ),
  ({ disabled }) => ({
    cursor: disabled ? 'default' : 'pointer',
  }),
  ({ hasLabel }) => (!hasLabel ? s('px-2') : { '& input': s('mr-2') })
)
const Input = styled.input(({ disabled }) => ({
  cursor: disabled ? 'default' : 'pointer',
}))
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

function CheckboxField({ name, id, label, disabled, ...props }) {
  const [fieldProps, { touched, error }] = useField({
    name,
    id,
    ...props,
  })

  const hasLabel = isNotNilOrEmpty(label)

  return (
    <Root>
      <Label hasLabel={hasLabel} disabled={disabled} {...props}>
        <Input
          type="checkbox"
          disabled={disabled}
          {...fieldProps}
          checked={fieldProps.value}
        />
        {label}
      </Label>
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { CheckboxField }
