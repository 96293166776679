import { values, equals, isNil } from '@soltalabs/ramda-extra'
import React, { useState, useEffect, useCallback } from 'react'
import { Link as LinkBase, useLocation } from 'react-router-dom'

import { NavigationBar } from './NavigationBar'

import { ReactComponent as OrdersIcon } from 'assets/feathers/file-text.svg'
import { ReactComponent as ShoppingBagIcon } from 'assets/feathers/shopping-bag.svg'
import { ReactComponent as ShoppingCartIcon } from 'assets/feathers/shopping-cart.svg'
import { ReactComponent as ProfileIcon } from 'assets/feathers/user.svg'
import { ReactComponent as ClientsIcon } from 'assets/feathers/users.svg'
import { styled, s } from 'lib/styled'

const Container = styled.div(s('flex-1 flex flex-row h-full', { overflow: 'hidden' }))
const SideBar = styled.div(
  s(
    'relative z-1 bg-white flex flex-column border-0 border-r-1 border-solid border-grey-light',
    {
      overflow: 'hidden',
      transition: '0.25s width ease, 0.25s box-shadow ease',
    }
  ),
  ({ isOpen }) => ({
    width: isOpen ? 226 : 0,
    boxShadow: isOpen ? s('shadow').boxShadow : 'none',
  })
)
const Branding = styled.div(
  s(
    'flex items-center justify-center py-4 border-0 border-b-1 border-solid border-grey-light'
  )
)
const Logo = styled.img(s('h-3'))
const Link = styled(LinkBase)(
  s(
    'no-decoration tracking-wide flex flex-row items-center text-sm font-normal d-box p-4 pl-3 text-black border-0 border-b-1 border-solid border-grey-lighter',
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      '&:hover': s('bg-primary-lighter'),
    }
  )
)

const LogoLink = styled(Link)({ '&:hover': { background: 'none' } })

const Content = styled.div(
  s('flex-1 flex flex-column', {
    '& > *': {
      overflow: 'hidden',
    },
  }),
  ({ isOpen }) => ({
    width: isOpen ? 'calc(100% - 226px)' : '100%',
  })
)

const routes = {
  providers: {
    key: 'providers',
    title: 'Providers',
    navBarTitle: 'Provider Management',
    path: '/providers',
    Icon: ProfileIcon,
  },
  listings: {
    key: 'listings',
    title: 'Listings',
    navBarTitle: 'Listing Management',
    path: '/listings',
    Icon: ShoppingBagIcon,
  },
  products: {
    key: 'products',
    title: 'Products',
    navBarTitle: 'Product Management',
    path: '/products',
    Icon: ShoppingCartIcon,
  },
  categories: {
    key: 'categories',
    title: 'Categories',
    navBarTitle: 'Category Management',
    path: '/categories',
    Icon: ShoppingBagIcon,
  },
  orders: {
    key: 'orders',
    title: 'Orders',
    navBarTitle: 'Order Management',
    path: '/orders',
    Icon: OrdersIcon,
  },
  clients: {
    key: 'clients',
    title: 'Clients',
    navBarTitle: 'Client Management',
    path: '/clients',
    Icon: ClientsIcon,
  },
}

function withNavigation(WrappedComponent) {
  return function NavigationComponent({ fullName, avatar, ...ownProps }) {
    const [showSidebar, setShowSidebar] = useState(true)
    const [currentRoute, setCurrentRoute] = useState(routes.providers)
    const location = useLocation()

    useEffect(() => {
      const newCurrentRoute = values(routes).find((route) =>
        equals(location.pathname, route.path)
      )

      if (isNil(newCurrentRoute)) {
        return
      }

      setCurrentRoute(newCurrentRoute)
    }, [location])

    const handleSidebarToggle = useCallback(() => {
      setShowSidebar(!showSidebar)
    })

    return (
      <Container>
        <SideBar isOpen={showSidebar}>
          <Branding>
            <LogoLink to="/listings">
              <Logo src="/logo.png" />
            </LogoLink>
          </Branding>

          {values(routes).map(({ key, path, title, Icon }) => (
            <Link key={key} to={path}>
              <Icon
                width={18}
                height={18}
                stroke="#007A6B"
                strokeWidth={1.5}
                style={s('mr-2')}
              />
              {title}
            </Link>
          ))}
        </SideBar>

        <Content isOpen={showSidebar}>
          <NavigationBar
            title={currentRoute.navBarTitle}
            showSidebar={showSidebar}
            onSidebarToggle={handleSidebarToggle}
            fullName={fullName}
            avatar={avatar}
          />

          <WrappedComponent {...ownProps} />
        </Content>
      </Container>
    )
  }
}

export { withNavigation }
