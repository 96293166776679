import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { getIdentityClientInstance } from 'lib/solta-id'

const service = http.extend({
  prefixUrl: CONFIG.API.URL,
  hooks: {
    beforeRequest: [
      async (request) => {
        const identityClient = await getIdentityClientInstance()
        const token = await identityClient.getTokenSilently()
        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const AuthService = {
  async readAccount() {
    try {
      return await service.get('my-account').json()
    } catch (error) {
      const response = await error.response.json()

      if (response.message.match(/UnverifiedAccount/)) {
        return undefined
      }

      throw error
    }
  },
}

export { AuthService }
