import { defaultTo } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import React from 'react'
import TextAreaBase from 'react-textarea-autosize'

import { styled, s } from 'lib/styled'

const Container = styled.div(s('flex flex-column'))
const Label = styled.label(
  s('inline-flex mb-2 uppercase tracking-wide text-xs text-gray-600')
)
const Input = styled(TextAreaBase)(
  s(
    'w-full bg-gray-200 text-sm text-black border-0 border-b-2 border-gray-500 rounded-lg px-3 py-2'
  ),
  ({ readOnly }) => (readOnly ? s('text-gray-700') : {})
)
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))
const CharacterLimit = styled.span(s('ml-a text-gray-400'))

function TextAreaField({
  name,
  id,
  label,
  className,
  containerProps,
  characterLimit = 0,
  ...props
}) {
  const [{ value, onChange: onFieldChange, ...fieldProps }, { touched, error }] =
    useField({
      name,
      id,
      ...props,
    })

  const useCharacterLimit = characterLimit > 0

  function handleChange(event) {
    if (useCharacterLimit && event.target.value.length > characterLimit) {
      return
    }

    onFieldChange(event)
  }

  return (
    <Container className={className} {...containerProps}>
      <Label htmlFor={name || id}>
        {label}

        {useCharacterLimit && (
          <CharacterLimit>
            {characterLimit - defaultTo('')(value).length}
          </CharacterLimit>
        )}
      </Label>

      <Input value={value} onChange={handleChange} {...fieldProps} {...props} />

      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}

export { TextAreaField }
