import { createModule } from '@soltalabs/stateless'

import { ClientService } from './service'

import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  sortBy: '',
  sortOrder: '',
  limit: 20,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

const fetchClients =
  (module) =>
  async (_, { turnPage = false, turnNext } = {}) => {
    const { filterQuery, limit, paging, sortBy, sortOrder } = module.getState()

    const next = calcNext(turnPage, turnNext, paging, limit)

    const {
      entities,
      order,
      next: newNext,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    } = await ClientService.list({
      query: filterQuery,
      next,
      sortBy,
      sortOrder,
      limit,
    })

    module.setState({
      entities,
      order,
      paging: {
        startRow: calcStartRow(newNext, limit, paging),
        next: newNext,
      },
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    })
  }

const turnPage =
  (module) =>
  ({ turnNext }) => {
    module.fetchClients(null, { turnPage: true, turnNext })
  }

const sortClients = (module) => (sortBy, sortOrder) => {
  module.setState({
    paging: {
      startRow: undefined,
      next: undefined,
    },
    sortBy,
    sortOrder,
  })

  module.fetchClients(null, { turnPage: false })
}

const filterClients = (module) => (query) => {
  module.setState({
    filterQuery: query,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

  module.fetchClients(null, { turnPage: false })
}

const inspectClient = (module) => async (id) => {
  module.setState({
    inspectedEntity: id,
  })

  const client = await ClientService.read(id)

  module.setState({
    entities: { [id]: client },
  })
}

const clientModule = createModule({
  name: 'client',
  initialState: INITIAL_STATE,
  decorators: {
    fetchClients,
    filterClients,
    sortClients,
    inspectClient,
    turnPage,
  },
})

export { clientModule }
