import { useFormikContext as useFormContext } from 'formik'
import React from 'react'

import { AddressField } from './AddressField'
import { Categories } from './Categories'
import { FullDescription } from './FullDescription'

import { NumberField } from 'components/common/NumberField'
import { ProviderSelectField } from 'components/common/ProviderSelectField'
import { TagInput } from 'components/common/TagInput'
import { TextField } from 'components/common/TextField'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Scroll = styled.div(
  s('flex-1', {
    overflowX: 'hidden',
    overflowY: 'auto',
  })
)

const Root = styled.div(s('flex flex-column mt-6'))

const providerNamePlaceholder = (values) => values?.details?.targetProvider?.name

const addressPlaceholder = (values) =>
  values?.details?.location?.formatted_address ??
  values?.details?.address?.formattedAddress ??
  null

function Details({ creating = false, isEditing }) {
  const { values, setFieldValue } = useFormContext()
  return (
    <Scroll>
      <Root>
        {creating && (
          <Row gutter={[16, 16]} style={s('justify-center')}>
            <Col span={20}>
              <ProviderSelectField
                name="details.targetProvider"
                label="Owner Provider"
                placeholder={providerNamePlaceholder(values)}
              />
            </Col>
          </Row>
        )}
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <TextField name="details.name" label="Product name" readOnly={!isEditing} />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <NumberField
              name="details.price"
              label="Product price"
              type="number"
              onChange={(e) => {
                setFieldValue(`details.price`, parseFloat(e.target.value))
              }}
              readOnly={!isEditing}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <FullDescription
              name="details.description"
              label="Product description"
              readOnly={!isEditing}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <Categories
              name="details.categories"
              label="Categories"
              readOnly={!isEditing}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            {/**
             * The following placeholder value is used to re-populate the address field visually
             * if the wizard is backtracked through. There are a few reasons for this, the most
             * important being that a proper location object is only added to the submission state
             * when an item in the downshift dropdown menu is clicked. We don't want people to manually
             * edit addresses instead of getting them from the maps api autocomplete.
             */}

            <AddressField
              name="details.location"
              label="Product Location"
              placeholder={addressPlaceholder(values)}
              readOnly={!isEditing}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <TagInput
              name="details.keywords"
              label="Product keywords"
              readOnly={!isEditing}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <FullDescription
              name="details.summary"
              label="Product summary"
              readOnly={!isEditing}
            />
          </Col>
        </Row>
      </Root>
    </Scroll>
  )
}

export { Details }
