import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { getIdentityClientInstance } from 'lib/solta-id'

const service = http.extend({
  prefixUrl: `${CONFIG.API.URL}`,
  hooks: {
    beforeRequest: [
      async (request) => {
        const identityClient = await getIdentityClientInstance()
        const token = await identityClient.getTokenSilently()
        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const UploadService = {
  async uploadFile(file, onProgress = () => {}) {
    const {
      id,
      uploadedAt,
      uploadedBy,
      signedUrl: presignedUrl,
    } = await service
      .post('uploads', {
        authorize: true,
        json: { fileName: file.name },
      })
      .json()

    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest()
      const performAsynchronously = true

      request.open('PUT', presignedUrl, performAsynchronously)
      request.setRequestHeader('content-type', file.type)

      request.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          onProgress(event.loaded, event.total)
        }
      }

      request.onload = () => {
        const success = request.status >= 200 && request.status < 300

        if (!success) {
          reject(new Error('Failed to upload file. Try again later.'))
        }

        resolve({
          id,
          uploadedAt,
          uploadedBy,
        })
      }

      request.send(file)
    })
  },
}

export { UploadService }
