import { isEmpty, includes } from '@soltalabs/ramda-extra'
import { Formik, Form } from 'formik'
import React from 'react'
import Modal from 'react-modal'
import * as Validator from 'yup'

import { TextAreaField } from '../../common/TextAreaField'

import { Button as ButtonBase } from 'components/common/Button'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('bg-white rounded-lg p-6'))

const Title = styled.h2(s('text-lg font-semibold'))

const CancelButton = styled(ButtonBase)(s('w-5 mx-1 bg-gray-100'))

const ConfirmButton = styled(ButtonBase)(
  s('w-5 mx-1 bg-red-lightest border-red-lighter'),
  {
    '&:not(:disabled)': { cursor: 'pointer' },
    '&:active': s('bg-red-lighter border-red-light'),
  }
)

const validationSchema = Validator.object().shape({
  reason: Validator.string().trim().required('Must provide a reason'),
})

const hotKeys = [13, 32]

Modal.setAppElement('#root')

function RejectModal({ showModal, setShowModal, actionType, updateStatus }) {
  const upperCaseFirstCharacter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1)

  function handleSubmit({ reason }) {
    updateStatus(actionType, reason)
  }

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          reason: '',
        }}
      >
        <Form>
          <Container>
            <Row justify="center">
              <Col sm={18} md={16} lg={14} xl={12}>
                <Root>
                  <Title>{upperCaseFirstCharacter(actionType)} reason</Title>

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <TextAreaField
                        name="reason"
                        minRows={15}
                        characterLimit={500}
                        onKeyDown={(e) => {
                          if (isEmpty(e.target.value) && includes(e.keyCode, hotKeys)) {
                            e.preventDefault()
                          }
                        }}
                        style={s('text-base')}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <CancelButton
                        type="button"
                        variant="secondary"
                        onClick={() => {
                          setShowModal(false)
                        }}
                      >
                        Cancel
                      </CancelButton>
                    </Col>

                    <Col span={12} style={s('text-right')}>
                      <ConfirmButton type="submit">Confirm</ConfirmButton>
                    </Col>
                  </Row>
                </Root>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </Modal>
  )
}

export { RejectModal }
