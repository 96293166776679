import { defaultTo, path } from '@soltalabs/ramda-extra'
import { useFormikContext } from 'formik'
import React from 'react'

import { DateAndTime } from './DateAndTime'
import { DetailsField } from './DetailsField'

import { RadioButtonField } from 'components/common/RadioButtonField'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { LISTING_TYPES } from 'modules/listing'

const Root = styled.div(s('flex flex-column'))

const SubTitle = styled.div(s('text-black text-base font-normal mt-6 mb-4'))

const Scroll = styled.div(
  s('flex-1', {
    overflowX: 'hidden',
    overflowY: 'auto',
  })
)

function Availabilities({ listingType }) {
  const { values } = useFormikContext()
  const { availabilities, selectedIndex, overview } = values

  const pricing = defaultTo([])(path([selectedIndex, 'pricing'])(availabilities))

  const startAtList = defaultTo([])(
    path([selectedIndex, 'startAtList'])(availabilities)
  )

  return (
    <Scroll>
      <Root>
        <Row gutter={[16, 16]}>
          <Col span={24} style={s('pl-8')}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <SubTitle>Available At</SubTitle>
                {listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.ONLINE) ? (
                  availabilities.map(({ name }, index) => (
                    <Row key={name}>
                      <Col>
                        <RadioButtonField
                          name="selectedIndex"
                          value={index}
                          label={name}
                        />
                      </Col>
                    </Row>
                  ))
                ) : (
                  <>
                    <Row>
                      <Col>
                        <DetailsField
                          style={{ overflowWrap: 'anywhere' }}
                          name={`availabilities[${selectedIndex}].location.address`}
                          label="Meeting Link"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DetailsField
                          name={`availabilities[${selectedIndex}].location.passcode`}
                          label="pin code"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DetailsField
                          readOnly
                          name={`availabilities[${selectedIndex}].location.timezone`}
                          label="timezone"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            {listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.ONLINE) && (
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <SubTitle>Location Details</SubTitle>

                  <Row>
                    <Col>
                      <DetailsField
                        name={`availabilities[${selectedIndex}].location.address`}
                        label="address"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <DetailsField
                        name={`availabilities[${selectedIndex}].location.transportNotes`}
                        label="transport Notes"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <DetailsField
                        readOnly
                        name={`availabilities[${selectedIndex}].location.parkingNotes`}
                        label="parking Notes"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <DetailsField
                        name={`availabilities[${selectedIndex}].location.hasWheelchairAccess`}
                        label="Wheelchair Access"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            <Row gutter={[16, 8]}>
              <Col span={24}>
                <SubTitle>Contact Details</SubTitle>

                <Row>
                  <Col>
                    <DetailsField
                      name={`availabilities[${selectedIndex}].contact.name`}
                      label="name"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <DetailsField
                      name={`availabilities[${selectedIndex}].contact.phoneNumber`}
                      label="mobile"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <DetailsField
                      name={`availabilities[${selectedIndex}].contact.email`}
                      label="email"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[16, 8]}>
              <Col span={24}>
                <SubTitle>Pricing</SubTitle>
                {pricing.map(({ name, price }) => (
                  <Row key={name} style={s('justify-between my-6 px-3')}>
                    <Col
                      span={15}
                      style={s(
                        'text-black border-solid border-0 border-b-1 border-gray-400 pb-1'
                      )}
                    >
                      {name}
                    </Col>
                    <Col
                      span={6}
                      style={s(
                        'text-black border-solid border-0 border-b-1 border-gray-400 pb-1 text-right'
                      )}
                    >
                      {`$${price}.00`}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>

            <Row gutter={[16, 8]}>
              <Col span={24}>
                <SubTitle>
                  Date & Time <span style={s('text-sm')}>(upcoming 5 events)</span>
                </SubTitle>
                <DateAndTime startAtList={startAtList} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Root>
    </Scroll>
  )
}

export { Availabilities }
