import { map, pick } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'
import { debounce } from 'lodash'

import { ListingService } from './service'

import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  sortBy: '',
  sortOrder: '',
  statuses: '',
  limit: 20,
  paging: {
    startRow: undefined,
    next: undefined,
  },
  dateRange: {
    from: undefined,
    to: undefined,
  },
})

const fetchListings =
  (module) =>
  async (_, { turnPage = false, turnNext } = {}) => {
    const { filterQuery, statuses, limit, paging, dateRange, sortBy, sortOrder } =
      module.getState()

    const next = calcNext(turnPage, turnNext, paging, limit)
    const { from, to } = dateRange

    const {
      entities,
      order,
      next: newNext,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    } = await ListingService.list({
      query: filterQuery,
      next,
      sortBy,
      sortOrder,
      statuses,
      limit,
      from,
      to,
    })

    module.setState({
      entities,
      order,
      paging: {
        startRow: calcStartRow(newNext, limit, paging),
        next: newNext,
      },
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    })
  }

const debouncedFetchListings = (module) => debounce(module.fetchListings, 350)

const updateListingStatus = (module) => async (_, id, actionType, reason) => {
  const listing = await ListingService[actionType](id, reason)

  module.setState({
    entities: { [id]: listing },
  })
}

const updateListingOverview = (module) => async (_, id, overviewList) => {
  const listing = await ListingService.update(id, overviewList)

  module.setState({
    entities: { [id]: listing },
  })
}

const updateListingMedia =
  (module) =>
  async (_, id, { updateList, createList, deleteList }) => {
    await Promise.all([
      ...map(
        (media) =>
          ListingService.updateMedia(
            id,
            pick(['id', 'tags', 'htmlContent', 'mediaType'], media)
          ),
        updateList
      ),
      ...map(
        (media) =>
          ListingService.createMedia(
            id,
            pick(['mediaType', 'content', 'tags', 'htmlContent'], media)
          ),
        createList
      ),
      ...map(
        (media) => ListingService.deleteMedia(id, pick(['id'], media)),
        deleteList
      ),
    ])

    const Listing = await ListingService.read(id)

    module.setState({
      entities: { [id]: Listing },
    })
  }

const filterDate =
  (module) =>
  ({ from, to }) => {
    module.setState({
      dateRange: {
        from,
        to,
      },
      paging: {
        startRow: undefined,
        next: undefined,
      },
    })

    module.fetchListings(null, { turnPage: false })
  }

const sortListings = (module) => (sortBy, sortOrder) => {
  module.setState({
    paging: {
      startRow: undefined,
      next: undefined,
    },
    sortBy,
    sortOrder,
  })

  module.fetchListings(null, { turnPage: false })
}

const turnPage =
  (module) =>
  ({ turnNext }) => {
    module.fetchListings(null, { turnPage: true, turnNext })
  }

const filterStatusListings = (module) => (statuses) => {
  module.setState({
    statuses,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })
  module.fetchListings(null, { turnPage: false })
}

const filterListings = (module) => (query) => {
  module.setState({
    filterQuery: query,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

  module.debouncedFetchListings(null, { turnPage: false })
}

const inspectListing = (module) => async (id) => {
  module.setState({
    inspectedEntity: id,
  })

  const listing = await ListingService.read(id)
  module.setState({
    entities: { [id]: listing },
  })
}

const listingModule = createModule({
  name: 'listing',
  initialState: INITIAL_STATE,
  decorators: {
    fetchListings,
    debouncedFetchListings,
    updateListingStatus,
    updateListingOverview,
    updateListingMedia,
    filterListings,
    filterStatusListings,
    sortListings,
    inspectListing,
    turnPage,
    filterDate,
  },
})

export { listingModule }
