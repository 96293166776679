import { path } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import React from 'react'

import { BackButton } from './BackButton'
import { NextButton } from './NextButton'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { Spinner } from 'components/common/Spinner'
import { useWizard } from 'lib/formik-wizard'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { deepMemo } from 'utils/react'

const Root = styled.div('flex flex-row')

const Memoized = deepMemo(WizardNavigation)

function WizardNavigation({
  shouldDisableBackButton = false,
  disableSubmitButton = false,
  ...props
}) {
  const { isSubmitting } = useFormContext()

  const { steps, currentStepIndex, submitContext, previous } = useWizard()

  const hasSubmissionError = submitContext.status === 'error'

  return (
    <Root {...props}>
      <Row justify="between">
        <Col xs={12}>
          <BackButton
            currentStepIndex={currentStepIndex}
            previous={previous}
            disabled={isSubmitting || shouldDisableBackButton}
          />
        </Col>

        <Col xs={12} style={s('flex flex-row-reverse items-center')}>
          <NextButton
            disableSubmitButton={disableSubmitButton}
            stepCount={steps.length}
            currentStepIndex={currentStepIndex}
            isSubmitting={isSubmitting}
          />

          {isSubmitting && <Spinner size={20} thickness={3} style={s('mr-3')} />}
          {!isSubmitting && hasSubmissionError && (
            <ErrorMessage style={s('mr-3')}>
              {path(['error', 'message'], submitContext)}
            </ErrorMessage>
          )}
        </Col>
      </Row>
    </Root>
  )
}

export { Memoized as WizardNavigation }
