import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { getIdentityClientInstance } from 'lib/solta-id'

const service = http.extend({
  prefixUrl: `${CONFIG.API.URL}/admin`,
  hooks: {
    beforeRequest: [
      async (request) => {
        const identityClient = await getIdentityClientInstance()
        const token = await identityClient.getTokenSilently()
        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const ServiceFeeService = {
  async getPlatformServiceFee() {
    return service.get(`servicefee/`).json()
  },

  async createPlatformServiceFee(platformServiceFeePercentage) {
    const config = {
      json: { platformServiceFeePercentage },
    }
    return service.post('servicefee/', config).json()
  },
}

export { ServiceFeeService }
