import React from 'react'

import { TextAreaField } from '../../common/TextAreaField'
import { TextField } from '../../common/TextField'

import { ReactComponent as FacebookIcon } from 'assets/img/facebook-round-filled.svg'
import { ReactComponent as InstagramIcon } from 'assets/img/instagram-outline.svg'
import { ReactComponent as TwitterIcon } from 'assets/img/twitter-filled.svg'
import { CheckboxField } from 'components/common/CheckboxField'
import { PhoneField, NUMBER_FORMATS } from 'components/common/PhoneField'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.section(s('p-4 my-4'))
const Title = styled.div(s('text-black text-xl font-normal'))
const Label = styled.label(s('mb-4 uppercase tracking-wide text-xs text-grey-dark'))

const ReadOnlyTag = styled.div(
  s('text-black py-1 bg-grey-light px-2 rounded-xl text-sm'),
  {
    opacity: 0.8,
  }
)

const Logo = styled.img(s('max-h-12 max-w-12'), { objectFit: 'contain' })

function BusinessDetails({ logo }) {
  return (
    <Root>
      <Row gutter={[16, 24]}>
        <Col style={s('pr-0')}>
          <Title>Business Details</Title>
        </Col>

        <Col>
          <ReadOnlyTag>read only</ReadOnlyTag>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={3} />

        <Col span={21}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Row>
                <Label>Logo</Label>
              </Row>

              <Row style={s('justify-center h-12')}>
                <Logo src={logo} />
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24} style={s('pl-0')}>
                  <Label>Social Networks</Label>
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <TextField
                    readOnly
                    name="facebook"
                    labelPosition="inline"
                    label={<FacebookIcon width={28} height={28} />}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <TextField
                    readOnly
                    name="instagram"
                    labelPosition="inline"
                    label={<InstagramIcon width={28} height={28} />}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <TextField
                    readOnly
                    name="twitter"
                    labelPosition="inline"
                    label={<TwitterIcon width={28} height={28} />}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField readOnly name="name" label={'Registered Company Name'} />
            </Col>

            <Col span={12}>
              <TextField
                readOnly
                name="businessRegistrationNumber"
                label="Company Number (NZBN/ABN)"
              />
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col span={12}>
              <TextField readOnly name="tradingName" label="Trading as" />
            </Col>

            <Col span={12}>
              <TextField readOnly name="taxNumber" label="GST Number" />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12} />

            <Col span={12}>
              <CheckboxField
                disabled
                name="notGSTRegistered"
                label="Not GST Registered"
                style={s('text-xs text-gray-600')}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField readOnly name="businessEmail" label="Contact E-mail" />
            </Col>

            <Col span={12}>
              <TextField readOnly name="website" label="Website" />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <PhoneField
                readOnly
                name="phone"
                label="Phone Number"
                placeholderNumberType={NUMBER_FORMATS.FIXED_LINE_OR_MOBILE}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextField
                containerProps={{ style: s('w-full') }}
                readOnly
                name="address"
                type="text"
                label="Service Location"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextAreaField
                containerProps={{ style: s('w-full') }}
                readOnly
                name="businessSummary"
                minRows={5}
                label="Business Summary"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Root>
  )
}

export { BusinessDetails }
