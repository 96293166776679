import { isNotNilOrEmpty } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import React from 'react'

import { styled, s } from 'lib/styled'
import { deepMemo } from 'utils/react'

const Root = styled.div(s('block my-2'))
const InputContainer = styled.div(s('flex'), ({ labelPosition }) =>
  labelPosition === 'inline' ? s('flex-row items-center') : s('flex-column')
)
const Label = styled.label(
  s('uppercase tracking-wide text-xs text-gray-600 '),
  ({ isVisible }) => (!isVisible ? s('hidden') : {}),
  ({ labelPosition }) => (labelPosition === 'inline' ? s('mr-2') : s('mb-2'))
)
const Input = styled.div(s('w-full text-sm text-black px-3 py-2'), {
  lineHeight: 1.5,
})

const Memoized = deepMemo(DetailsField)

function DetailsField({
  name,
  id,
  label,
  labelPosition = 'above',
  className,
  containerProps,
  ...props
}) {
  const [fieldProps] = useField({
    name,
  })

  return (
    <Root className={className} {...containerProps}>
      <InputContainer labelPosition={labelPosition}>
        <Label
          isVisible={isNotNilOrEmpty(label)}
          htmlFor={name || id}
          labelPosition={labelPosition}
        >
          {label}
        </Label>

        <Input {...fieldProps} {...props}>
          {fieldProps.value ? (
            fieldProps.value
          ) : (
            <div style={s('text-gray-600')}>Unspecified</div>
          )}
        </Input>
      </InputContainer>
    </Root>
  )
}

export { Memoized as DetailsField }
