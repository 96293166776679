import { connect } from '@soltalabs/stateless'
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { CategoriesDashboard } from './categories/CategoriesDashboard'
import { ClientsDashboard } from './clients/ClientsDashboard'
import { ListingsDashboard } from './listings/ListingsDashboard'
import { OrdersDashboard } from './orders/OrdersDashboard'
import { ProductsDashboard } from './products/ProductsDashboard'
import { ProvidersDashboard } from './providers/ProvidersDashboard'

import { withNavigation } from 'components/common/withNavigation'
import { fullName$, avatar$ } from 'modules/auth'

const ConnectedDashboards = connect(() => ({
  fullName: fullName$,
  avatar: avatar$,
}))(withNavigation(Dashboards))

function Dashboards() {
  return (
    <Switch>
      <Route path="/providers" component={ProvidersDashboard} />
      <Route path="/listings" component={ListingsDashboard} />
      <Route path="/products" component={ProductsDashboard} />
      <Route path="/categories" component={CategoriesDashboard} />
      <Route exact path="/orders/:id">
        <OrdersDashboard displayTable={false} />
      </Route>
      <Route exact path="/orders">
        <OrdersDashboard displayTable={true} />
      </Route>
      <Route exact path="/clients/:id">
        <ClientsDashboard displayTable={false} />
      </Route>
      <Route exact path="/clients">
        <ClientsDashboard displayTable={true} />
      </Route>
      <Redirect to="/providers" />
    </Switch>
  )
}

export { ConnectedDashboards as Dashboards }
