import { isNil, prop } from '@soltalabs/ramda-extra'
import React from 'react'

import { safelyParse } from 'utils/safelyParse'

const rowsPerPage = 10

const createEmptyRows = (fetchedList) => {
  for (let index = fetchedList.length; index < rowsPerPage; index += 1) {
    fetchedList.push({
      id: null,
      reference: null,
      listing: null,
      venue: null,
      createdAt: null,
      total: null,
    })
  }
}

export const Rows = (list) => {
  return React.useMemo(() => {
    const ordersList = list.slice()
    if (ordersList.length < rowsPerPage) {
      createEmptyRows(ordersList)
    }
    createEmptyRows(ordersList)
    return ordersList.map(({ id, reference, listing, venue, createdAt, total }) => ({
      id,
      reference,
      title: prop('title', listing),
      venue: prop('name', venue),
      createdAt: isNil(createdAt) ? null : safelyParse(createdAt),
      total,
    }))
  }, [list])
}
