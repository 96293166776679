/* eslint-disable no-nested-ternary */
import React from 'react'

import { ToggleSwitch } from 'components/common/ToggleSwitch'
import { styled, s } from 'lib/styled'

const Container = styled.div(s('my-2 flex flex-row, w-full'), {
  '& .react-switch-selector-option': s('my-1 mx-2'),
})

const ApproveOptions = [
  {
    label: 'Not require',
    value: {
      listingApprovalRequired: false,
    },
    selectedBackgroundColor: '#0097e6',
  },
  {
    label: 'Require',
    value: {
      listingApprovalRequired: true,
    },
    selectedBackgroundColor: '#cc1f1a',
  },
]

const StatusOptions = [
  {
    label: 'Active',
    value: {
      status: 'provider_status_active',
    },
    selectedBackgroundColor: '#0097e6',
  },
  {
    label: 'Suspend',
    value: {
      status: 'provider_status_suspended',
    },
    selectedBackgroundColor: '#fbc531',
  },
  {
    label: 'Ban',
    value: {
      status: 'provider_status_banned',
    },
    selectedBackgroundColor: '#cc1f1a',
  },
]
const disabledCss = {
  opacity: '0.5',
  transition: 'opacity 0.25s',
  'pointer-events': 'none',
}

function StyledToggleSwitch({ onChange, selectedOption, disabled, toggleType }) {
  return (
    <Container style={disabled ? disabledCss : {}}>
      <ToggleSwitch
        onChange={onChange}
        options={toggleType === 'approve' ? ApproveOptions : StatusOptions}
        initialSelectedIndex={selectedOption}
        backgroundColor={'#353b48'}
        fontColor={'#f5f6fa'}
      />
    </Container>
  )
}

export { StyledToggleSwitch }
