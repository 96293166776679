/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
import React, { useEffect, useRef } from 'react'

import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'

import { styled, s } from 'lib/styled'

const Table = styled.table(s('flex flex-column'), {
  overflow: 'auto',
  borderSpacing: 0,
  minWidth: '100%',
})

function TableBase({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  rows,
  onClick,
  sortBy,
  sortOrder,
  onSort,
  domain,
  ...props
}) {
  const table = useRef(null)
  useEffect(() => {
    table.current.scrollTop = 0
  }, [rows])
  return (
    <>
      <Table {...getTableProps()} ref={table}>
        <TableHeader
          headerGroups={headerGroups}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
        />
        <TableBody
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          rows={rows}
          onClick={onClick}
          domain={domain}
          {...props}
        />
      </Table>
    </>
  )
}

export { TableBase }
