import React from 'react'

import { SearchInput } from 'components/common/SearchInput'
import { styled, s } from 'lib/styled'

const Root = styled.div(
  s(
    'flex flex-row px-4 py-3 items-center border-0 border-b-1 border-solid border-grey-lighter'
  )
)

function Filters({ filterQuery, filterOrders }) {
  return (
    <Root>
      <SearchInput
        value={filterQuery}
        onChange={filterOrders}
        placeholder="Search by name or mobile"
        containerProps={{ style: s('w-16') }}
      />
    </Root>
  )
}

export { Filters }
