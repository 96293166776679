import { equals } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React, { useEffect, memo } from 'react'

import { Spinner } from 'components/common/Spinner'
import { Dashboards } from 'components/Dashboards'
import { useIdentity } from 'lib/solta-id-react'
import { styled, s } from 'lib/styled'
import { authModule, isAdmin$, isCheckingAdminStatus$ } from 'modules/auth'

import 'styles/vendors.scss'
import 'styles/index.css'

const Container = styled.div(
  s('h-full flex flex-column', {
    backgroundColor: '#F8F8F8',
  }),
  ({ centered }) => (centered ? s('items-center justify-center') : {})
)

const Card = styled.div(s('self-center max-w-24 p-6 shadow-md bg-white rounded-lg'))
const Title = styled.h1(s('text-primary text-3xl font-regular mb-8'))
const Message = styled.p(s('text-base'), { lineHeight: 1.15 })

const ConnectedApp = connect(() => ({
  isCheckingAdminStatus: isCheckingAdminStatus$,
  isAdmin: isAdmin$,
}))(App)

function App({ isCheckingAdminStatus, isAdmin }) {
  const { loading, isAuthenticated, loginWithRedirect, logout } = useIdentity()

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      loginWithRedirect()
    }

    if (isAuthenticated && !loading) {
      authModule.checkAccountAdminStatus()
    }
  }, [loading, isAuthenticated, loginWithRedirect])

  useEffect(() => {
    if (!loading && !isCheckingAdminStatus && (!isAdmin || !isAuthenticated)) {
      setTimeout(() => {
        logout()
      }, 5000)
    }

    return () => {}
  }, [loading, isAuthenticated, logout, isAdmin, isCheckingAdminStatus])

  if (loading || isCheckingAdminStatus) {
    return (
      <Container centered>
        <Spinner />
      </Container>
    )
  }

  if (!isAuthenticated || !isAdmin) {
    return (
      <Container centered>
        <Card>
          <Title>Unauthorized</Title>

          <Message>Please contact a platform administrator to gain access.</Message>

          <Message style={s('mb-0')}>
            <i>Logging out in 5 seconds...</i>
          </Message>
        </Card>
      </Container>
    )
  }

  return (
    <Container>
      <Dashboards />
    </Container>
  )
}

export default memo(ConnectedApp, equals)
