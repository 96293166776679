/* eslint-disable react/jsx-key */
import { includes, isNil, path } from '@soltalabs/ramda-extra'
import React from 'react'
import { Link as LinkBase } from 'react-router-dom'

import { styled, s } from 'lib/styled'

const unlinkableTableList = ['orderHistory']

const Link = styled(LinkBase)(s('py-3 no-decoration text-black'), {
  display: 'table-cell',
  verticalAlign: 'middle',
})

const Tr = styled.tr(
  s(
    'flex items-center text-black no-decoration text-xs border-0 border-b-1 border-solid border-gray-200'
  ),
  {
    height: '44px',
    '&:hover': s('bg-blue-lightest'),
    '&:last-child': {
      '& td': {
        borderBottom: 0,
      },
    },
  },
  ({ domain, isEmptyRow }) =>
    (includes(domain, unlinkableTableList) || isEmptyRow) && {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    }
)

const Td = styled.div(s('px-3'), {
  margin: '0',
  '&:last-child': {
    borderRight: 0,
  },
})

const Tbody = styled.tbody(s('text-center text-sm'))

function TableBody({ getTableBodyProps, prepareRow, rows, onClick, domain }) {
  return (
    <>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)

          return (
            <Tr
              {...row.getRowProps({
                onClick: () => onClick(row.original.id),
              })}
              domain={domain}
              isEmptyRow={isNil(path(['original', 'id'], row))}
            >
              {row.cells.map((cell) => {
                return (
                  <Link
                    to={`/${domain}/${row.original.id}`}
                    style={{
                      width: path(['column', 'width'], cell),
                      textAlign: path(['column', 'textAlign'], cell),
                    }}
                  >
                    <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  </Link>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </>
  )
}

export { TableBody }
