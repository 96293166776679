import React from 'react'
import { Link as LinkBase } from 'react-router-dom'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { SearchInput } from 'components/common/SearchInput'
import { styled, s } from 'lib/styled'

const Root = styled.div(
  s(
    'flex flex-row px-4 py-3 items-center border-0 border-b-1 border-solid border-gray-400'
  ),
  {
    'z-index': 2,
  }
)

const Link = styled(LinkBase)(s('ml-a'))

const CreateProductButton = styled.div(
  s(
    'ml-a flex flex-row items-center bg-primary text-white font-normal border-0 text-xs uppercase tracking-wide pl-2 pr-3 py-2 rounded-lg'
  ),
  {
    '&:hover': s('bg-primary-dark', {
      cursor: 'pointer',
    }),
  }
)

function Filters({ filterQuery, filterProducts }) {
  return (
    <Root>
      <SearchInput
        value={filterQuery}
        onChange={filterProducts}
        placeholder="Search by Name"
      />

      <Link to="/products/new-product">
        <CreateProductButton>
          <PlusIcon
            width={16}
            height={16}
            stroke="#FFF"
            strokeWidth="3px"
            style={s('mr-1')}
          />
          New Product
        </CreateProductButton>
      </Link>
    </Root>
  )
}

export { Filters }
