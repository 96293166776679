import { path } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext, FieldArray } from 'formik'
import React, { useState, useEffect, useLayoutEffect } from 'react'

import { ProductOptionsValues } from './ProductOptionValues'

import { ReactComponent as TrashCan } from 'assets/feathers/trash-can.svg'
import { TextField } from 'components/common/TextField'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { findAllVariants } from 'utils/findAllVariants'

const AddNewButton = styled.button(
  s(
    'flex flex-row items-center mt-2 mb-2 mr-a ml-a bg-primary text-white font-normal border-0 text-xs uppercase tracking-wide pl-2 pr-3 py-2 rounded-lg'
  ),
  {
    '&:hover': s('bg-primary-dark', {
      cursor: 'pointer',
    }),
    ':disabled': s('bg-white text-grey', {
      cursor: 'auto',
    }),
  }
)

const Input = styled.input(
  s(
    'bg-gray-200 text-sm text-black border-0 border-b-2 border-gray-500 rounded-lg px-3 py-2 mr-a ml-a mt-2 mb-4'
  )
)

const ErrorMessage = styled.span(s('text-red mr-a ml-a'))

const OptionConfig = styled.div(s('ml-a mr-a mt-2 pt-2 pb-2 pl-2 pr-2'), {
  border: '2px solid rgba(225, 225, 225, 0.5)',
  'border-radius': '4px',
})

function ProductOptions({ isEditing, setMustGenerate }) {
  const { values, setFieldValue, errors } = useFormContext()

  const optError = path(['optionsAndVariants', 'options'], errors)
  const varError = path(['optionsAndVariants', 'variants'], errors)

  const [isMounting, setIsMounting] = useState(true)

  const [showVariantGenerationError, setShowVariantGenerationError] = useState()
  const [lastField, setLastField] = useState(null)

  const setFieldRefCallback = (element, index) => {
    if (index === values.optionsAndVariants.options.length - 1) setLastField(element)
  }

  useLayoutEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    lastField?.focus()
  }, [lastField])

  useEffect(() => {
    if (isMounting) {
      setIsMounting(false)
      return
    }
    setMustGenerate({
      value: true,
    })
  }, [values.optionsAndVariants.options])

  async function generateVariants() {
    try {
      const variantList = findAllVariants(
        values.optionsAndVariants.options,
        values.details.price
      )
      await setMustGenerate({
        value: false,
      })
      setFieldValue('optionsAndVariants.variants', variantList)
    } catch (e) {
      setShowVariantGenerationError(true)
    }
  }

  return (
    <>
      <FieldArray
        name="optionsAndVariants.options"
        render={(optionArrayHelpers) => (
          <>
            {values.optionsAndVariants.options.map((option, optionIndex) => (
              <OptionConfig key={optionIndex}>
                <Row gutter={[16, 16]} style={s('justify-center')}>
                  <Col>
                    <TextField
                      name={`optionsAndVariants.options[${optionIndex}].name`}
                      label="Option Name"
                      fast={false}
                      innerRef={(e) => setFieldRefCallback(e, optionIndex)}
                      readOnly={!isEditing}
                    />
                  </Col>
                  <Col>
                    {isEditing && (
                      <TrashCan
                        onClick={() => optionArrayHelpers.remove(optionIndex)}
                        width={34}
                        height={34}
                        style={s('mt-4', { cursor: 'pointer' })}
                      />
                    )}
                  </Col>
                </Row>
                <hr />
                <ProductOptionsValues
                  option={option}
                  optionIndex={optionIndex}
                  isEditing={isEditing}
                />
              </OptionConfig>
            ))}
            <Input
              key={values.optionsAndVariants.options.length}
              placeholder="Add New Option"
              onChange={(e) =>
                optionArrayHelpers.push({ name: e.target.value, values: [] })
              }
              disabled={!isEditing}
            />
          </>
        )}
      />
      <AddNewButton type="button" onClick={generateVariants} disabled={!isEditing}>
        Generate Variants
      </AddNewButton>
      {showVariantGenerationError && (
        <ErrorMessage>
          Could not generate variants. Please try again later.
        </ErrorMessage>
      )}
      {typeof optError === 'string' && <ErrorMessage>{optError}</ErrorMessage>}
      {typeof varError === 'string' && <ErrorMessage>{varError}</ErrorMessage>}
    </>
  )
}

export { ProductOptions }
