/* eslint-disable react/display-name */
import React from 'react'

import { BlockButton } from './BlockButton'
import { MarkButton } from './MarkButton'
import { Menu } from './Menu'

import { ReactComponent as BoldIcon } from 'assets/feathers/format_bold.svg'
import { ReactComponent as ItalicIcon } from 'assets/feathers/format_italic.svg'
import { ReactComponent as BulletListIcon } from 'assets/feathers/format_list_bulleted.svg'
import { ReactComponent as NumberListIcon } from 'assets/feathers/format_list_numbered.svg'
import { ReactComponent as QuoteIcon } from 'assets/feathers/format_quote.svg'
import { ReactComponent as UnderlineIcon } from 'assets/feathers/format_underlined.svg'
import { ReactComponent as HeadingOneIcon } from 'assets/feathers/looks_one.svg'
import { ReactComponent as HeadingTwoIcon } from 'assets/feathers/looks_two.svg'
import { s } from 'lib/styled'

export const Toolbar = React.forwardRef(({ charsLeft, className, ...props }, ref) => (
  <Menu {...props} ref={ref}>
    <MarkButton format="bold" icon={<BoldIcon />} />
    <MarkButton format="italic" icon={<ItalicIcon />} />
    <MarkButton format="underline" icon={<UnderlineIcon />} />
    <BlockButton format="heading-one" icon={<HeadingOneIcon />} />
    <BlockButton format="heading-two" icon={<HeadingTwoIcon />} />
    <BlockButton format="block-quote" icon={<QuoteIcon />} />
    <BlockButton format="numbered-list" icon={<NumberListIcon />} />
    <BlockButton format="bulleted-list" icon={<BulletListIcon />} />
    <span style={s('ml-a')}>{charsLeft} characters left</span>
  </Menu>
))
