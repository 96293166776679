import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { CreateProductWizard } from './CreateProductWizard'
import { ProductDetails } from './ProductDetails'
import { ProductsTable } from './ProductsTable'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('flex-1 flex flex-row p-6'))

function ProductsDashboard() {
  return (
    <Root>
      <Switch>
        <Route path="/products/new-product" component={CreateProductWizard} />
        <Route path="/products/:id" component={ProductDetails} />
        <Route exact path="/products" component={ProductsTable} />
        <Redirect to="/products" />
      </Switch>
    </Root>
  )
}

export { ProductsDashboard }
