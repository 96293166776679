/* eslint-disable camelcase */

import RRule from 'rrule'

const EVENT_REOCCURRENCE = new Enum({
  NEVER: { value: 'event_reoccurrence_never', readableValue: 'Never' },
  DAILY: {
    value: 'event_reoccurrence_daily',
    readableValue: 'Daily',
    rruleConfig: { interval: 1, freq: RRule.DAILY },
  },
  WEEKLY: {
    value: 'event_reoccurrence_weekly',
    readableValue: 'Weekly',
    rruleConfig: { interval: 1, freq: RRule.WEEKLY },
  },
  FORTNIGHTLY: {
    value: 'event_reoccurrence_fortnightly',
    readableValue: 'Fortnightly',
    rruleConfig: { interval: 2, freq: RRule.WEEKLY },
  },
  MONTHLY: {
    value: 'event_reoccurrence_monthly',
    readableValue: 'Monthly',
    rruleConfig: { interval: 1, freq: RRule.MONTHLY },
  },
})

const BOOKING_WINDOW = new Enum({
  ON_DAY: { value: 'booking_window_on_day', readableValue: 'On day' },
  TWELVE_HOURS: {
    value: 'booking_window_twelve_hours',
    readableValue: '12 hours prior',
  },
  ONE_DAY: {
    value: 'booking_window_one_day',
    readableValue: '1 day prior',
  },
  THREE_DAYS: {
    value: 'booking_window_three_days',
    readableValue: '3 days prior',
  },
  ONE_WEEK: { value: 'booking_window_one_week', readableValue: 'One week prior' },
})

const MEDIA_TYPES = new Enum({
  PHOTO: { value: 'listing_media_photo' },
  VIDEO: { value: 'listing_media_video' },
  BROCHURE: { value: 'listing_media_brochure' },
  TERMS_CONDITIONS: { value: 'listing_media_terms_conditions' },
  CANCELLATION: { value: 'listing_media_cancellation' },
  RISK_ASSESSMENT: { value: 'resouce_media_risk_assessment' },
  MISC_DOCUMENT: { value: 'listing_media_misc_document' },
})

const LISTING_TYPES = new Enum({
  PHYSICAL: {
    value: 'listing_type_physical',
  },
  ONLINE: {
    value: 'listing_type_online',
  },
  COMMUNITY: {
    value: 'listing_type_community',
  },
})

const LISTING_CATEGORIES = {
  ADVENTURES: 'listing_adventures',
  BACKPACKING: 'listing_backpacking',
  COUPLES: 'listing_couples',
  CRUISING: 'listing_cruising',
  DIVING: 'listing_diving',
  FAMILY: 'listing_family',
  GOLF: 'listing_golf',
  LUXURY: 'listing_luxury',
  NATURE: 'listing_nature',
  SAILING: 'listing_sailing',
  SUSTAINABILITY: 'listing_sustainability',
  SNORKELING: 'listing_snorkeling',
  SURFING: 'listing_surfing',
  VOLUNTEERING: 'listing_volunteering',
  WEDDINGS: 'listing_weddings',
}

const LISTING_CATEGORY_LABELS = {
  listing_adventures: 'Adventures',
  listing_backpacking: 'Backpacking',
  listing_couples: 'Couples',
  listing_cruising: 'Cruises',
  listing_diving: 'Diving',
  listing_family: 'Family',
  listing_golf: 'Golf',
  listing_luxury: 'Luxury',
  listing_nature: 'Nature',
  listing_sailing: 'Sailing',
  listing_sustainability: 'Sustainability',
  listing_snorkeling: 'Snorkeling',
  listing_surfing: 'Surfing',
  listing_volunteering: 'Volunteer',
  listing_weddings: 'Wedding',
}

export {
  EVENT_REOCCURRENCE,
  BOOKING_WINDOW,
  MEDIA_TYPES,
  LISTING_CATEGORIES,
  LISTING_CATEGORY_LABELS,
  LISTING_TYPES,
}
