import { connect } from '@soltalabs/stateless'
import React, { useLayoutEffect, useState } from 'react'

import { Table } from '../common/CategoriesTable'
import { CreateCategoryFormModal } from '../CreateCategoryFormModal'

import { Columns } from './Columns'
import { Filters } from './Filters'
import { Rows } from './Rows'

import { styled, s } from 'lib/styled'
import {
  categoryList$,
  filterQuery$,
  limit$,
  paging$,
  isLoadingCategories$,
  categoryModule,
} from 'modules/category'

const Container = styled.div(s('flex-1 flex flex-column bg-white rounded-lg shadow-sm'))

const ConnectedCategoriesTable = connect(() => ({
  categoryList: categoryList$,
  isLoadingCategories: isLoadingCategories$,
  filterQuery: filterQuery$,
  paging: paging$,
  limit: limit$,
}))(CategoriesTable)

const { fetchCategories, filterCategories, turnPage } = categoryModule

function CategoriesTable({ categoryList, filterQuery, total, paging, limit }) {
  useLayoutEffect(() => {
    fetchCategories()
  }, [])

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  function handleOpenCreateCategoryModal() {
    setIsCreateModalOpen(true)
  }

  const columns = Columns()
  const rows = Rows(categoryList)
  const { startRow, next } = paging

  return (
    <Container>
      <Filters
        filterQuery={filterQuery}
        filterCategories={filterCategories}
        total={total}
        openCreateCategoryModal={handleOpenCreateCategoryModal}
      />
      <Table
        columns={columns}
        onClick={() => {}}
        data={rows}
        next={next}
        startRow={startRow}
        limit={limit}
        turnPage={turnPage}
        domain={'categories'}
      />

      <CreateCategoryFormModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
      />
    </Container>
  )
}

export { ConnectedCategoriesTable as CategoriesTable }
