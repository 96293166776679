import { find, prop, propEq } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import React from 'react'
import Select from 'react-select'

import { GENDERS } from 'constants/genders'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('w-full flex-1 flex flex-column'))

const styles = {
  control: (_, state) => {
    const basicStyle = s(
      'min-w-10 flex flex-row items-center border-solid border-0 border-b-2 border-gray-500 rounded-lg bg-gray-200 text-sm'
    )
    const disabledStyle = state.isDisabled
      ? s('bg-gray-200 text-gray-600', {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        })
      : {}

    return { ...basicStyle, ...disabledStyle }
  },

  option: (provided) => ({
    ...provided,
    '&:hover': s('bg-gray-400'),
  }),

  dropdownIndicator: () => s('p-0 px-2 relative', { top: 1 }),

  valueContainer: (provided) => ({
    ...provided,
    ...s('pr-0 justify-center'),
  }),

  indicatorSeparator: () => s('hidden'),

  singleValue: (_, state) => {
    return state.isDisabled ? s('p-2 text-gray-700') : s('p-2 text-black')
  },
}

const Label = styled.label(s('uppercase tracking-wide text-xs text-gray-600 mb-2'))
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

const options = [
  { label: 'All genders', value: [GENDERS.UNSPECIFIED] },
  { label: 'Boys', value: [GENDERS.MALE] },
  { label: 'Girls', value: [GENDERS.FEMALE] },
]

function GenderField({ name, id, label, type, readOnly, ...props }) {
  const [fieldProps, { touched, error }] = useField({ name, id, ...props })
  const { value, onChange } = fieldProps
  const selectedLabel = prop('label', find(propEq('value', value), options))
  const currentOption = selectedLabel ? { label: selectedLabel } : null

  return (
    <Root>
      <Label>{label}</Label>

      <Select
        value={currentOption}
        type={type}
        onChange={(selectedOption) => {
          const event = { target: { value: selectedOption.value, name } }
          onChange(event)
        }}
        options={options}
        isSearchable={false}
        menuPlacement="top"
        styles={styles}
        isDisabled={readOnly}
      />
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { GenderField }
