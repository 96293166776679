import { useField } from 'formik'
import React from 'react'

import { styled, s } from 'lib/styled'
import { deepMemo } from 'utils/react'

const Container = styled.div(
  s('flex-1 flex flex-row text-black px-3 py-2 items-center rounded-lg'),
  ({ isChecked }) =>
    isChecked ? s('bg-gray-200 border-gray-500') : s('bg-transparent '),
  ({ readOnly }) => ({
    cursor: readOnly ? 'default' : 'pointer',
  })
)
const Label = styled.label(s('m-0 ml-2 text-sm'), ({ readOnly }) =>
  readOnly ? s('text-gray-700', { cursor: 'default' }) : { cursor: 'pointer' }
)
const Input = styled.input(({ disabled }) => ({
  cursor: disabled ? 'default' : 'pointer',
}))

const Memoized = deepMemo(RadioButtonField)

function RadioButtonField({
  id,
  name,
  label,
  value,
  containerProps,
  readOnly,
  ...props
}) {
  const [{ value: fieldValue, onChange }] = useField({
    name,
  })
  const isChecked = value === Number(fieldValue)
  function handleChange() {
    onChange({ target: { value, name } })
  }

  return (
    <Container isChecked={isChecked} readOnly={readOnly} onClick={handleChange}>
      <Input
        type="radio"
        name={name}
        value={value}
        checked={value === Number(fieldValue)}
        onChange={handleChange}
        disabled={readOnly}
        {...props}
      />
      <Label readOnly={readOnly} htmlFor={id}>
        {label}
      </Label>
    </Container>
  )
}

export { Memoized as RadioButtonField }
