import { path, pluck, defaultTo, prop } from '@soltalabs/ramda-extra'

import { LISTING_TYPES } from 'modules/listing'

function populateAvailabilityList(listingType, availabilities) {
  const isPhysicalLocation = listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.ONLINE)

  return defaultTo([], availabilities).map(({ venue, contact, pricings, events }) => ({
    name: path(['name'], venue),

    location: isPhysicalLocation
      ? {
          address: path(['address', 'formattedAddress'], venue),
          hasWheelchairAccess: venue.wheelchairAccessible ? 'Available' : 'Unavailable',
          transportNotes: path(['transports', 0], venue),
          parkingNotes: path(['parkings', 0], venue),
        }
      : {
          address: path(['address', 'formattedAddress'], venue),
          passcode: prop('passcode', venue),
          timezone: prop('timezone', venue),
        },

    contact: {
      name: contact.name,
      phoneNumber: contact.phone,
      email: contact.email,
    },

    pricing: pricings.map(({ name, price }) => ({
      name,
      price,
    })),

    startAtList: pluck('startAt', events),
  }))
}

export { populateAvailabilityList }
