import { prop } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { serviceFeeModule } from './module'

const { state$ } = serviceFeeModule

const platformServiceFeePercentage$ = select(state$, (state) =>
  prop('platformServiceFeePercentage', state)
)
export { platformServiceFeePercentage$ }
