import { omit } from '@soltalabs/ramda-extra'
import React from 'react'
import * as Validator from 'yup'

import { Categories } from './Categories'
import { FullDescription } from './FullDescription'
import { GenderField } from './GenderField'

import { TagInput } from 'components/common/TagInput'
import { Wizard } from 'lib/formik-wizard'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { listingModule, LISTING_TYPES } from 'modules/listing'
import { convertToHtml } from 'utils/convertSlateData'

const Root = styled.div(s('flex flex-column mt-4'))

const Scroll = styled.div({
  overflowX: 'hidden',
  overflowY: 'auto',
})

const { object, string, array, number } = Validator
const noSpecialCharacters = string()
  .trim()
  .required('This field is required')
  .matches(/^[a-zA-Z0-9 _-]+$/g, 'No special character')

const validationSchema = Validator.object({
  overview: object({
    description: object().shape({
      charsCount: number().max(3000, '3000 characters limit'),
    }),
    categories: array().required('Select at least one'),
    keywords: array().of(noSpecialCharacters),
    itemsToBring: array().of(noSpecialCharacters),
    itemsToWear: array().of(noSpecialCharacters),
  }),
})

function OverviewTab({ listingType, isEditing, setIsEditing, id }) {
  async function handleSubmit({ overview }) {
    const overviewList = omit(['description'], overview)
    overviewList.description = convertToHtml({ children: overview.description.text })

    await listingModule.updateListingOverview(null, id, overviewList)

    setIsEditing(false)
  }

  return (
    <Wizard.Step
      key={isEditing}
      id="overview"
      title="Overview"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Scroll>
        <Root>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FullDescription
                disabled={!isEditing}
                name="overview.description"
                label="Full description"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Categories
                readOnly={!isEditing}
                name="overview.categories"
                label="Categories"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TagInput
                readOnly={!isEditing}
                name="overview.keywords"
                label="Keywords"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TagInput
                readOnly={!isEditing}
                name="overview.itemsToBring"
                label="Need to bring"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TagInput
                readOnly={!isEditing}
                name="overview.itemsToWear"
                label="What to wear"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            {listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY) && (
              <Col span={6} style={s('mr-a')} />
            )}

            <Col>
              <GenderField
                readOnly={!isEditing}
                name="overview.genders"
                label="Gender"
              />
            </Col>
          </Row>
        </Root>
      </Scroll>
    </Wizard.Step>
  )
}

export { OverviewTab }
