import React from 'react'

import { PhotoGallery } from './PhotoGallery'

import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Scroll = styled.div(
  s('flex-1', {
    overflowX: 'hidden',
    overflowY: 'auto',
  })
)

const Label = styled.label(s('uppercase tracking-wide text-xs text-gray-600'))
const Root = styled.div(s('flex flex-column mt-6'))

function Media({ isEditing }) {
  return (
    <Scroll>
      <Root>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Label htmlFor="media.photos">Photos (max size: 6MB)</Label>
            <PhotoGallery name="media.photos" disabled={!isEditing} />
          </Col>
        </Row>
      </Root>
    </Scroll>
  )
}

export { Media }
