/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'

import { StyledCell } from '../../common/withTable/StyledCell'

import { s } from 'lib/styled'

const statusMapper = {
  'Waiting Approval': 'bg-primary-lightest',
  Approved: 'bg-green-lightest',
  Suspended: 'bg-orange-lightest saturate-2',
  Rejected: 'bg-red-lightest saturate-2',
}

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Listing Title',
        accessor: 'title',
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '28%',
      },
      {
        Header: 'Type',
        accessor: 'listingType',
        disableSortBy: true,
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '18%',
      },
      {
        Header: 'Created on',
        accessor: 'createdAt',
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '18%',
      },
      {
        Header: 'Provider',
        accessor: 'providerName',
        Cell: (row) => <StyledCell style={s('text-left')} value={row.value} />,
        width: '18%',
      },
      {
        Header: () => <StyledCell value="Status" />,
        accessor: 'status',
        width: '18%',
        textAlign: 'left',
        Cell: (row) => (
          <StyledCell
            style={s(`rounded-md inline-block px-2 py-1 ${statusMapper[row.value]}`)}
            value={row.value}
          />
        ),
      },
    ],
    []
  )
}
