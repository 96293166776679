import React from 'react'
import { useHistory } from 'react-router-dom'

import { DetailsStep } from './ProductWizard/DetailsStep'
import { MediaStep } from './ProductWizard/MediaStep'
import { OptionsVariantsStep } from './ProductWizard/OptionsVariantsStep'

import { Spinner } from 'components/common/Spinner'
import { WizardNavigation } from 'components/common/WizardNavigation'
import { WizardProgress } from 'components/common/WizardProgress'
import { Wizard } from 'lib/formik-wizard'
import { styled, s } from 'lib/styled'
import { getProductFormInitialValues } from 'utils/getProductFormInitialValues'

const Root = styled.div(
  s('flex flex-column bg-white w-full rounded-lg px-6 pt-1 pb-4 shadow-sm')
)

function CreateProductWizard() {
  const history = useHistory()

  function handleSubmit() {
    history.push('/products')
  }
  return (
    <Root>
      <Wizard
        isLoading={false}
        loadingComponent={<Spinner style={s('mt-12')} />}
        basePath="/products/new-product"
        formProps={{
          style: s('flex-1 flex flex-column', { overflow: 'hidden' }),
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={getProductFormInitialValues()}
      >
        <WizardProgress />
        <DetailsStep />
        <MediaStep />
        <OptionsVariantsStep />

        <WizardNavigation style={s('mt-6')} />
      </Wizard>
    </Root>
  )
}

export { CreateProductWizard }
