/* eslint-disable no-shadow */
import React, { useState, useEffect, useContext } from 'react'

import { getIdentityClientInstance } from './solta-id'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const IdentityContext = React.createContext()
export const useIdentity = () => useContext(IdentityContext)
export const IdentityProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  domain,
  clientId,
  redirectUrl,
  audience,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [identityClient, setClient] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const identityClient = await getIdentityClientInstance({
        domain,
        clientId,
        redirectUrl,
        audience,
      })
      setClient(identityClient)

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await identityClient.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await identityClient.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await identityClient.getUser()
        setUser(user)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await identityClient.loginWithPopup(params)
    } catch (error) {
      // catch error here
    } finally {
      setPopupOpen(false)
    }
    const user = await identityClient.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await identityClient.handleRedirectCallback()
    const user = await identityClient.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }

  return (
    <IdentityContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => identityClient.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => identityClient.loginWithRedirect(...p),
        getTokenSilently: (...p) => identityClient.getTokenSilently(...p),
        getTokenWithPopup: (...p) => identityClient.getTokenWithPopup(...p),
        logout: () => identityClient.logout({ returnTo: redirectUrl }),
      }}
    >
      {children}
    </IdentityContext.Provider>
  )
}
