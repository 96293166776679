import { connect } from '@soltalabs/stateless'
import React, { useLayoutEffect } from 'react'

import { Table } from '../../common/withTable'

import { Columns } from './Columns'
import { Filters } from './Filters'
import { Rows } from './Rows'

import { styled, s } from 'lib/styled'
import {
  clientModule,
  clientList$,
  isLoadingClients$,
  inspectedClient$,
  filterQuery$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
} from 'modules/client'

const Container = styled.div(s('flex-1 flex flex-column bg-white rounded-lg shadow-sm'))

const ConnectedClientsTable = connect(() => ({
  clientList: clientList$,
  isLoadingClients: isLoadingClients$,
  inspectedClient: inspectedClient$,
  filterQuery: filterQuery$,
  sortBy: sortBy$,
  sortOrder: sortOrder$,
  paging: paging$,
  limit: limit$,
}))(ClientsTable)

const { fetchClients, inspectClient, filterClients, sortClients, turnPage } =
  clientModule

function ClientsTable({
  clientList,
  filterQuery,
  sortBy,
  sortOrder,
  total,
  paging,
  limit,
}) {
  useLayoutEffect(() => {
    fetchClients()
  }, [])

  const columns = Columns()
  const rows = Rows(clientList)
  const { startRow, next } = paging

  return (
    <Container>
      <Filters filterQuery={filterQuery} filterOrders={filterClients} total={total} />
      <Table
        columns={columns}
        data={rows}
        onClick={inspectClient}
        sortBy={sortBy}
        sortOrder={sortOrder}
        next={next}
        startRow={startRow}
        limit={limit}
        onSort={sortClients}
        turnPage={turnPage}
        domain={'clients'}
      />
    </Container>
  )
}

export { ConnectedClientsTable as ClientsTable }
