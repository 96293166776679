import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { ProviderDetails } from './ProviderDetails'
import { ProvidersTable } from './ProvidersTable'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('flex-1 flex flex-row p-6'))

function ProvidersDashboard() {
  return (
    <Root>
      <Switch>
        <Route exact path="/providers/:id" component={ProviderDetails} />
        <Route exact path="/providers" component={ProvidersTable} />

        <Redirect to="/providers" />
      </Switch>
    </Root>
  )
}

export { ProvidersDashboard }
