/* eslint-disable complexity */
import { isEmpty, isNil } from '@soltalabs/ramda-extra'
import escapeHtml from 'escape-html'
import { Node, Text } from 'slate'
import { jsx } from 'slate-hyperscript'

const ELEMENT_TAGS = {
  A: (el) => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'block-quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  UL: () => ({ type: 'bulleted-list' }),
}

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
}

// eslint-disable-next-line complexity
export const convertToHtml = (node) => {
  if (Text.isText(node)) {
    if (node.bold && node.italic && node.underline) {
      return `<strong><em><u>${escapeHtml(node.text)}</u></em></strong>`
    }
    if (node.bold && node.italic) {
      return `<strong><em>${escapeHtml(node.text)}</em></strong>`
    }
    if (node.bold && node.underline) {
      return `<strong><u>${escapeHtml(node.text)}</u></strong>`
    }
    if (node.italic && node.underline) {
      return `<em><u>${escapeHtml(node.text)}</u></em>`
    }
    if (node.bold) {
      return `<strong>${escapeHtml(node.text)}</strong>`
    }

    if (node.italic) {
      return `<em>${escapeHtml(node.text)}</em>`
    }

    if (node.underline) {
      return `<u>${escapeHtml(node.text)}</u>`
    }
    return escapeHtml(node.text)
  }
  const children = node.children.map((n) => convertToHtml(n)).join('')

  switch (node.type) {
    case 'block-quote':
      return `<blockquote>${children}</blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'heading-one':
      return `<h1>${children}</h1>`
    case 'heading-two':
      return `<h2>${children}</h2>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    default:
      return children
  }
}

const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent
  }
  if (el.nodeType !== 1) {
    return null
  }
  if (el.nodeName === 'BR') {
    return '\n'
  }
  const { nodeName } = el

  const parent = el

  let children = Array.from(parent.childNodes).map(deserialize).flat()
  children = isEmpty(children) ? [''] : children
  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map((child) => jsx('text', attrs, child))
  }

  return children
}

export function convertFromHtml(html) {
  const fragment = new DOMParser().parseFromString(
    isNil(html) ? '<p></p>' : html,
    'text/html'
  )
  return deserialize(fragment.body)
}

export function calcCharscount(nodes) {
  const plainText = nodes.map((n) => Node.string(n)).join('\n')
  return plainText.length
}
