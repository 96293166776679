import { isNotEmpty } from '@soltalabs/ramda-extra'
import React from 'react'

import { Avatar } from 'components/common/Avatar'
import { styled, s } from 'lib/styled'
import { safelyParse } from 'utils/safelyParse'

const Container = styled.section(
  s('flex-grow-0 p-4 pb-8 pt-0 border-0 border-solid border-grey-lighter w-1/3 text-sm')
)
const Header = styled.div(s('flex flex-row py-6'))
const Title = styled.h2(s('m-0 text-lg font-normal'))
const Column = styled.div(s('flex-1 flex flex-column'))
const Row = styled.div(
  s('flex-1 flex flex-row my-2', {
    '&:first-of-type': s('mt-0'),
    '&:last-of-type': s('mb-0'),
  })
)
const Text = styled.div(
  s(
    'h-full flex flex-row items-center justify-center pb-10 text-lg text-gray-600 font-bold'
  ),
  { opacity: 0.6 }
)

const Details = styled.div(s('my-2'))

function ChildProfile({ childProfiles }) {
  return (
    <Container>
      <Header>
        <Title>Child Profile</Title>
      </Header>
      {isNotEmpty(childProfiles) ? (
        childProfiles.map(({ gender, photo, fullName, dob, ages, preferredName }) => (
          <Row key={fullName} style={s('items-center justify-center mx-10 my-4')}>
            <Avatar src={photo} size={96} placeholderSize={80} style={s('m-0')} />
            <Column style={s('ml-10')}>
              <Details style={s('font-bold text-primary-darker text-base')}>
                {fullName} ({preferredName})
              </Details>
              <Details>
                {gender} ({ages}yrs)
              </Details>
              <Details>DOB: {safelyParse(dob)}</Details>
            </Column>
          </Row>
        ))
      ) : (
        <Text>No profile yet</Text>
      )}
    </Container>
  )
}

export { ChildProfile }
