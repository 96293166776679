/* eslint-disable complexity */
import { isNil } from '@soltalabs/ramda-extra'
import React from 'react'

import { safelyParse } from 'utils/safelyParse'

const totalRows = 20

const createEmptyRows = (fetchedList) => {
  for (let index = fetchedList.length; index < totalRows; index += 1) {
    fetchedList.push({
      id: null,
      numberOfChildren: null,
      accountDetails: {
        name: null,
        email: null,
        mobile: null,
        registeredAt: null,
      },
      totalSpendings: null,
      totalOrders: null,
    })
  }
}

export const Rows = (list) => {
  return React.useMemo(() => {
    const clientsList = list.slice()
    if (clientsList.length < totalRows) {
      createEmptyRows(clientsList)
    }
    createEmptyRows(clientsList)
    return clientsList.map(
      ({ id, numberOfChildren, accountDetails, totalSpendings, totalOrders }) => ({
        id,
        name: accountDetails.name,
        email: accountDetails.email,
        mobile: accountDetails.mobile,
        childProfile: numberOfChildren,
        registeredAt: isNil(accountDetails.registeredAt)
          ? null
          : safelyParse(accountDetails.registeredAt),
        totalOrders,
        totalAmount: totalSpendings,
      })
    )
  }, [list])
}
