import { map, prop, path, pathOr } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { providerModule } from './module'

const { state$, effectState$ } = providerModule

const providerEntities$ = select(state$, (state) => prop('entities', state))
const order$ = select(state$, (state) => prop('order', state))
const inspectedEntity$ = select(state$, (state) => prop('inspectedEntity', state))

const providerList$ = select(order$, providerEntities$, (order, enities) =>
  map((id) => path([id], enities), order)
)
const inspectedProvider$ = select(inspectedEntity$, providerEntities$, (id, entities) =>
  prop(id, entities)
)

const sortBy$ = select(state$, (state) => prop('sortBy', state))

const sortOrder$ = select(state$, (state) => prop('sortOrder', state))

const filterQuery$ = select(state$, (state) => prop('filterQuery', state))

const filterStatus$ = select(state$, (state) => prop('status', state))

const paging$ = select(state$, (state) => prop('paging', state))

const limit$ = select(state$, (state) => prop('limit', state))

const fetchProvidersStatus$ = select(
  providerEntities$,
  effectState$,
  (providerEntities, effectState) =>
    pathOr('pending', [providerEntities, 'status'], effectState)
)

const inspectProviderStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectProvider', inspectedEntity, 'status'], effectState)
)

const isLoadingProviders$ = select(
  fetchProvidersStatus$,
  (status) => status === 'pending'
)

const isInspectingProvider$ = select(
  inspectProviderStatus$,
  (status) => status === 'pending'
)

const getIsUpdatingProvider$ = (id) =>
  select(effectState$, (effectState) => {
    const updateProviderStatus = path(['updateProvider', id, 'status'], effectState)

    return updateProviderStatus === 'pending'
  })

export {
  providerList$,
  filterQuery$,
  filterStatus$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
  isLoadingProviders$,
  inspectedEntity$,
  inspectedProvider$,
  isInspectingProvider$,
  getIsUpdatingProvider$,
}
