import { createModule } from '@soltalabs/stateless'

import { OrderHistoryService } from './service'

import { clientModule as ClientModule } from 'modules/client'
import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'

const INITIAL_STATE = Object.freeze({
  entities: {},
  filterQuery: '',
  order: [],
  sortBy: '',
  sortOrder: '',
  limit: 10,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

const fetchOrderHistory =
  (module) =>
  async (_, { turnPage = false, turnNext } = {}) => {
    const { filterQuery, limit, paging, sortBy, sortOrder } = module.getState()
    const { inspectedEntity: id } = ClientModule.getState()

    const next = calcNext(turnPage, turnNext, paging, limit)

    const {
      entities,
      order,
      next: newNext,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    } = await OrderHistoryService.list({
      id,
      query: filterQuery,
      next,
      sortBy,
      sortOrder,
      limit,
    })

    module.setState({
      entities,
      order,
      paging: {
        startRow: calcStartRow(newNext, limit, paging),
        next: newNext,
      },
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    })
  }

const turnPage =
  (module) =>
  ({ turnNext }) => {
    module.fetchOrderHistory(null, { turnPage: true, turnNext })
  }

const sortOrderHistory = (module) => (sortBy, sortOrder) => {
  module.setState({
    paging: {
      startRow: undefined,
      next: undefined,
    },
    sortBy,
    sortOrder,
  })

  module.fetchOrderHistory(null, { turnPage: false })
}

const orderHistoryModule = createModule({
  name: 'OrderHistory',
  initialState: INITIAL_STATE,
  decorators: {
    fetchOrderHistory,
    sortOrderHistory,
    turnPage,
  },
})

export { orderHistoryModule }
