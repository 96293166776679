import React from 'react'
import * as Validator from 'yup'

import { PhotoGallery } from './PhotoGallery'

import { Wizard } from 'lib/formik-wizard'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { listingModule } from 'modules/listing'
import { createMediaSubmitList } from 'utils/createMediaSubmitList'

const Root = styled.div(s('flex flex-column w-full mt-6'))

const Label = styled.label(s('uppercase tracking-wide text-xs text-gray-600'))

const Scroll = styled.div({
  overflowX: 'hidden',
  overflowY: 'auto',
})

const { array, object } = Validator

const validationSchema = object({
  media: object({
    photos: array().required('Upload at least one photo'),
  }),
})

function MediaTab({ isEditing, setIsEditing, id, initialPhotos }) {
  async function handleSubmit({ media }) {
    const { updateList, createList, deleteList } = createMediaSubmitList(
      media,
      initialPhotos
    )

    await listingModule.updateListingMedia(null, id, {
      updateList,
      createList,
      deleteList,
    })

    setIsEditing(false)
  }

  return (
    <Wizard.Step
      key={isEditing}
      id="media"
      title="Media"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Scroll>
        <Root>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Label htmlFor="photos">Photos (max size: 6MB)</Label>
              <PhotoGallery name="media.photos" disabled={!isEditing} />
            </Col>
          </Row>
        </Root>
      </Scroll>
    </Wizard.Step>
  )
}

export { MediaTab }
