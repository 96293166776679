import { find, prop, propEq } from '@soltalabs/ramda-extra'
import React from 'react'
import SelectBase from 'react-select'

import { styled, s } from 'lib/styled'

const Container = styled.div()
const Select = styled(SelectBase)()

const styles = {
  control: () =>
    s(
      'min-w-12 flex flex-row items-center min-h-a border-solid border-0 border-b-2 border-gray-500 rounded-lg bg-gray-200 text-sm',
      { '&:hover': s('bg-gray-400', { cursor: 'pointer', overflow: 'hidden' }) }
    ),
  option: (provided) => ({
    ...provided,
    '&:hover': s('bg-gray-400'),
  }),
  dropdownIndicator: () => s('p-0 px-2 relative', { top: 1 }),
  valueContainer: (provided) => ({
    ...provided,
    ...s('px-3 py-1'),
  }),
  indicatorSeparator: () => s('hidden'),
  singleValue: () => s('text-black'),
}

function StatusSelector({ value, onChange, statusOptions, ...props }) {
  const filterLabel = prop('label', find(propEq('value', value), statusOptions))
  return (
    <Container {...props}>
      <Select
        value={{ label: filterLabel }}
        onChange={(selectedOption) => {
          onChange(selectedOption.value)
        }}
        placeholder="All listings"
        options={statusOptions}
        isSearchable={false}
        styles={styles}
      />
    </Container>
  )
}

export { StatusSelector }
