import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { ListingDetails } from './ListingDetails'
import { ListingsTable } from './ListingsTable'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('flex-1 flex flex-row p-6'))

function ListingsDashboard() {
  return (
    <Root>
      <Switch>
        <Route path="/listings/:id" component={ListingDetails} />
        <Route exact path="/listings" component={ListingsTable} />

        <Redirect to="/listings" />
      </Switch>
    </Root>
  )
}

export { ListingsDashboard }
