import React from 'react'

import { TextField } from '../../common/TextField'

import { Avatar } from 'components/common/Avatar'
import { PhoneField, NUMBER_FORMATS } from 'components/common/PhoneField'
import { PROVIDER_STATUSES } from 'constants/providerStatus'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.section(
  s('p-4 border-0 border-b-1 border-solid border-grey-lighter my-4')
)

const ReadOnlyTag = styled.div(
  s('text-black py-1 bg-grey-light px-2 rounded-xl text-sm'),
  {
    opacity: 0.8,
  }
)
const Title = styled.div(s('text-black text-xl font-normal'))

const statusMapper = {
  [PROVIDER_STATUSES.ACTIVE]: 'Active',
  [PROVIDER_STATUSES.SUSPENDED]: 'Suspended',
  [PROVIDER_STATUSES.BANNED]: 'Banned',
}

function AccountDetails({ avatar, status }) {
  return (
    <Root>
      <Row gutter={[16, 24]}>
        <Col style={s('pr-0')}>
          <Title>My Account</Title>
        </Col>

        <Col>
          <ReadOnlyTag>read only</ReadOnlyTag>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={3}>
          <Row style={s('justify-center')}>
            <Avatar src={avatar} size={96} placeholderSize={80} style={s('m-0')} />
          </Row>

          <Row style={s('justify-center')}>
            <Title style={s('mt-4 uppercase tracking-wide text-sm text-grey-darker')}>
              {statusMapper[status]}
            </Title>
          </Row>
        </Col>

        <Col span={21}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField readOnly name="firstName" label="First name" />
            </Col>

            <Col span={12}>
              <TextField readOnly name="lastName" label="Last name" />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <PhoneField
                readOnly
                name="mobile"
                label="Mobile Number"
                placeholderNumberType={NUMBER_FORMATS.FIXED_LINE_OR_MOBILE}
              />
            </Col>

            <Col span={12}>
              <TextField readOnly name="email" label="E-mail" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Root>
  )
}

export { AccountDetails }
