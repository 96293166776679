import {
  update,
  append,
  findIndex,
  isNil,
  isEmpty,
  omit,
  isNotNil,
  path,
} from '@soltalabs/ramda-extra'
import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { WizardProvider } from './context'
import { SteppedForm } from './SteppedForm'

import { Spinner } from 'components/common/Spinner'

function Wizard({
  isLoading,
  loadingComponent = <Spinner />,
  basePath,
  children,
  ...props
}) {
  const history = useHistory()
  const routeMatch = useRouteMatch(`${ensureTrailingSlash(basePath)}:stepId`)

  const [submitContext, setSubmitContext] = useState({})
  const [steps, setSteps] = useState([])
  const [currentStepIndex, setCurrentStepIndex] = useState(-1)
  const currentStep = currentStepIndex !== -1 ? steps[currentStepIndex] : undefined

  useCurrentStepFromPath({ isLoading, currentStep, steps, routeMatch })
  useSyncedHistory({ basePath, currentStepIndex, history, steps })

  function useCurrentStepFromPath({ isLoading, currentStep, steps, routeMatch }) {
    useEffect(() => {
      ;(() => {
        // The wizard doesn't render steps until it is loaded. Running this effect
        // before loading is complete will cause the wizard to lose the step that the
        // user navigated to before loading, so the fix is to simply hold off on
        // grabbing the current step until loading is complete.
        if (isLoading) {
          return
        }

        const noStepsAreRegistered = isEmpty(steps)
        const currentStepAlreadySet = isNotNil(currentStep)

        if (noStepsAreRegistered || currentStepAlreadySet) {
          return
        }

        const stepId = path(['params', 'stepId'], routeMatch)
        const currentStepIndexFromPath = findIndex((step) => step.id === stepId, steps)
        const matchingStepNotFound = currentStepIndexFromPath === -1

        if (matchingStepNotFound) {
          setCurrentStepIndex(0)
          return
        }

        setCurrentStepIndex(currentStepIndexFromPath)
      })()
    }, [isLoading, currentStep, steps, routeMatch])
  }

  function useSyncedHistory({ basePath, currentStepIndex, history, steps }) {
    useLayoutEffect(() => {
      const currentStepAlreadySet = currentStepIndex !== -1
      if (currentStepAlreadySet) {
        const { id } = steps[currentStepIndex]
        history.push(`${ensureTrailingSlash(basePath)}${id}`)
      }
    }, [basePath, currentStepIndex, history, steps])
  }

  function next() {
    if (isNil(currentStep)) {
      return
    }

    const currentStepIndex = findIndex((step) => step.id === currentStep.id, steps)
    const nextStepIndex =
      currentStepIndex !== steps.length - 1 ? currentStepIndex + 1 : currentStepIndex

    setCurrentStepIndex(nextStepIndex)
  }

  function previous() {
    if (isNil(currentStep)) {
      return
    }

    const currentStepIndex = findIndex((step) => step.id === currentStep.id, steps)
    const previousStepIndex =
      currentStepIndex !== 0 ? currentStepIndex - 1 : currentStepIndex

    setCurrentStepIndex(previousStepIndex)
  }

  function go(id) {
    const gotoStepIndex = findIndex((step) => step.id === id, steps)

    setCurrentStepIndex(gotoStepIndex)
  }

  function register(step) {
    setSteps((prevSteps) => {
      const existingStepIndex = findIndex(
        (prevStep) => prevStep.id === step.id,
        prevSteps
      )

      if (existingStepIndex !== -1) {
        return update(existingStepIndex, step, prevSteps)
      }

      return append(step, prevSteps)
    })
  }

  return (
    <WizardProvider
      value={{
        steps,
        currentStep,
        currentStepIndex,
        submitContext,
        setSubmitContext,
        next,
        previous,
        go,
        register,
      }}
    >
      <SteppedForm {...omit(['render', 'validate', 'validationSchema'], props)}>
        {isLoading ? loadingComponent : children}
      </SteppedForm>
    </WizardProvider>
  )
}

function ensureTrailingSlash(value) {
  if (value.charAt(value.length - 1) === '/') {
    return value
  }

  return `${value}/`
}

export { Wizard }
