/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react'

import { s } from 'lib/styled'

export const Button = React.forwardRef(
  ({ className, active, reversed, ...props }, ref) => (
    <span
      {...props}
      ref={ref}
      style={s('mr-4', {
        cursor: 'pointer',
        opacity: active ? null : '0.5',
      })}
    />
  )
)
