import { last, pickThenRename, noop } from '@soltalabs/ramda-extra'
import { Formik, Form } from 'formik'
import React from 'react'

import { useWizard } from './context'

function SteppedForm({ onSubmit = noop, children, formProps, ...props }) {
  const { steps, currentStep, next, setSubmitContext } = useWizard()

  const {
    validate,
    validationSchema,
    onStepSubmit = noop,
  } = pickThenRename(
    ['validate', 'validationSchema', 'onSubmit'],
    { onSubmit: 'onStepSubmit' },
    currentStep
  )

  async function handleSubmit(values, form) {
    setSubmitContext({})

    const lastStep = last(steps)
    const isLastStep = lastStep.id === currentStep.id

    try {
      if (isLastStep) {
        await onStepSubmit(values, form)

        return onSubmit(values, form)
      }

      await onStepSubmit(values, form)
    } catch (error) {
      setSubmitContext({
        status: 'error',
        error,
      })

      return Promise.reject(error)
    }

    form.setTouched({})
    form.setSubmitting(false)
    next()

    return Promise.resolve()
  }

  return (
    <Formik
      validate={validate}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      {...props}
    >
      <Form {...formProps}>{children}</Form>
    </Formik>
  )
}

export { SteppedForm }
