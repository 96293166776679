import { isNilOrEmpty, path, prop } from '@soltalabs/ramda-extra'
import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import * as Validator from 'yup'

import { ProductOptions } from '../common/ProductForms/ProductOptions'
import { Variants } from '../common/ProductForms/Variants'

import { TabEditing } from './TabEditing'

import { styled, s } from 'lib/styled'
import { productModule } from 'modules/product'
import { deepMemo } from 'utils/react'

const Scroll = styled.div(
  s('flex-1', {
    overflowX: 'hidden',
    overflowY: 'auto',
  })
)

const Root = styled.div(s('flex flex-column mt-6'))
const ErrorMessage = styled.span(s('text-red mr-a ml-a'))

const Memoized = deepMemo(OptionsVariantsTab)

const { array, object, number, string } = Validator

const noSpecialCharacters = string()
  .trim()
  .matches(/^[a-zA-Z0-9 _-]+$/g, 'No special characters')
  .required()
  .label('This')

const variantSchema = object({
  optionSet: array().of(noSpecialCharacters).required(),
  price: number().optional(),
})

const optionSchema = object({
  values: array().required('Please provide at least one option value'),
})

const validationSchema = Validator.object({
  optionsAndVariants: object({
    options: array(optionSchema).required('Please provide at least one option'),
    variants: array(variantSchema).required(
      'Please Generate Variants before submission'
    ),
  }),
})

function OptionsVariantsTab({
  inspectedProduct,
  initialValues,
  isEditing,
  tabTitle,
  currentTab,
  isEditingDisabled,
  setIsEditing,
}) {
  const [showGenerationPrompt, setShowGenerationPrompt] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [mustGenerate, setMustGenerate] = useState(false)

  if (currentTab !== tabTitle) {
    return null
  }
  const { id } = inspectedProduct

  const setMustGenerateOverload = ({ value }) => {
    setMustGenerate(value)
    setShowGenerationPrompt(value)
  }

  async function handleSubmit({ optionsAndVariants }) {
    if (mustGenerate || isNilOrEmpty(optionsAndVariants.variants)) {
      setShowGenerationPrompt(true)
      return
    }
    setShowGenerationPrompt(false)
    await productModule.updateProductOptionsVariants(null, id, optionsAndVariants)
    setMustGenerate(false)
    setIsEditing(false)
  }

  return (
    <Formik
      style={s('flex-1 flex flex-row', { overflow: 'hidden' })}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form style={s('flex flex-column', { overflow: 'hidden' })}>
        <Scroll>
          <Root>
            <ProductOptions
              isEditing={isEditing}
              initialOptionValues={path(
                'optionsAndVariants',
                'options',
                inspectedProduct
              )}
              setMustGenerate={setMustGenerateOverload}
              initialOptions={prop('options', inspectedProduct)}
            />
            <Variants isEditing={isEditing} />
          </Root>
        </Scroll>
        {showGenerationPrompt && (
          <ErrorMessage>
            Please generate new variants before saving changes.
          </ErrorMessage>
        )}
        <TabEditing
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isCanceled={isCanceled}
          setIsCanceled={setIsCanceled}
          disabled={isEditingDisabled}
        />
      </Form>
    </Formik>
  )
}

export { Memoized as OptionsVariantsTab }
