import { connect } from '@soltalabs/stateless'
import { debounce } from 'lodash'
import React, { useLayoutEffect, useCallback, useMemo } from 'react'

import { Columns } from './Columns'
import { Filters } from './Filters'
import { Rows } from './Rows'

import { Table } from 'components/common/withTable'
import { styled, s } from 'lib/styled'
import {
  providerModule,
  providerList$,
  isLoadingProviders$,
  inspectedProvider$,
  filterQuery$,
  filterStatus$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
} from 'modules/provider'
import { serviceFeeModule, platformServiceFeePercentage$ } from 'modules/serviceFee'

const Container = styled.div(s('flex-1 flex flex-column bg-white rounded-lg shadow-sm'))
const ServiceFeeEditor = styled.div(s('flex flex-row px-4 py-3 items-center'))
const Input = styled.input(
  s(
    'flex flex-row items-center px-3 py-1 border-solid border-0 border-b-2 border-gray-500 rounded-lg bg-gray-200 text-base'
  )
)
const TableHeader = styled.div(
  s(
    'w-full flex flex-row justify-between pr-4 border-0 border-b-1 border-solid border-gray-400'
  )
)
const Text = styled.div(s('text-black-600 font-bold'))

const ConnectedProvidersTable = connect(() => ({
  providerList: providerList$,
  isLoadingProviders: isLoadingProviders$,
  filterQuery: filterQuery$,
  inspectedProvider: inspectedProvider$,
  filterStatus: filterStatus$,
  sortBy: sortBy$,
  sortOrder: sortOrder$,
  paging: paging$,
  limit: limit$,
  platformServiceFeePercentage: platformServiceFeePercentage$,
}))(ProvidersTable)

const {
  fetchProviders,
  inspectProvider,
  filterProviders,
  filterStatusProviders,
  sortProviders,
  turnPage,
} = providerModule

const { fetchPlatformServiceFee, createPlatformServiceFee } = serviceFeeModule

function ProvidersTable({
  providerList,
  filterQuery,
  filterStatus,
  sortBy,
  sortOrder,
  paging,
  limit,
  platformServiceFeePercentage,
}) {
  useLayoutEffect(() => {
    fetchProviders()
    fetchPlatformServiceFee()
  }, [platformServiceFeePercentage])

  const debouncedCreatePlatformServiceFee = useMemo(
    () => debounce(createPlatformServiceFee, 350),
    []
  )

  const onSetPlatformServiceFee = useCallback((event) => {
    debouncedCreatePlatformServiceFee(null, event.target.value)
  }, [])

  const columns = Columns()
  const rows = Rows(providerList)
  const { next, startRow } = paging
  return (
    <Container>
      <TableHeader>
        <Filters
          filterQuery={filterQuery}
          filterProviders={filterProviders}
          filterStatus={filterStatus}
          filterStatusProviders={filterStatusProviders}
        />
        <ServiceFeeEditor>
          <Text>Universal Service Fee</Text>
          <Input
            type="number"
            min="0"
            placeholder={platformServiceFeePercentage}
            onChange={onSetPlatformServiceFee}
          />
          <Text>%</Text>
        </ServiceFeeEditor>
      </TableHeader>

      <Table
        columns={columns}
        data={rows}
        onClick={inspectProvider}
        sortBy={sortBy}
        sortOrder={sortOrder}
        next={next}
        startRow={startRow}
        limit={limit}
        onSort={sortProviders}
        turnPage={turnPage}
        domain={'providers'}
      />
    </Container>
  )
}

export { ConnectedProvidersTable as ProvidersTable }
