import { isNil } from 'ramda'
import React from 'react'

import { ReactComponent as TrashCan } from 'assets/feathers/trash-can.svg'
import { PRODUCT_STATUSES } from 'constants/productStatus'
import { styled, s } from 'lib/styled'
import { safelyParse } from 'utils/safelyParse'

const totalRows = 20

const createEmptyRows = (fetchedList) => {
  for (let index = fetchedList.length; index < totalRows; index += 1) {
    fetchedList.push({
      id: null,
      title: null,
      createdAt: null,
      status: null,
    })
  }
}

const statusMapper = {
  [PRODUCT_STATUSES.DRAFT]: 'Draft',
  [PRODUCT_STATUSES.AWAITING_APPROVAL]: 'Awaiting Approval',
  [PRODUCT_STATUSES.LISTED]: 'Approved',
  [PRODUCT_STATUSES.SUSPENDED]: 'Suspended',
  [PRODUCT_STATUSES.REJECTED]: 'Rejected',
}

const StyledTrashCan = styled(TrashCan)(s('mt-1 p-2'), {
  '&:hover': s('rounded-full bg-grey-light', { fill: '#000000' }),
})

export const Rows = (list) =>
  React.useMemo(() => {
    if (list.length < totalRows) {
      createEmptyRows(list)
    }
    return list.map(({ id, name, createdAt, status, providerName }) => ({
      id,
      name,
      createdAt: isNil(createdAt) ? null : safelyParse(createdAt),
      status: statusMapper[status],
      providerName,
      delete: <StyledTrashCan key="delete" fill="#8795a1" width={30} height={30} />,
      isRowRequiresActiveProvider: statusMapper[status] === 'Draft',
    }))
  }, [list])
