import { useField } from 'formik'
import isHotkey from 'is-hotkey'
import React, { useCallback, useMemo } from 'react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, withReact, Slate } from 'slate-react'

import { Element } from './Element'
import { toggleMark } from './helpers'
import { Leaf } from './Leaf'
import { Toolbar } from './Toolbar'

import { styled, s } from 'lib/styled'
import { calcCharscount, convertFromHtml } from 'utils/convertSlateData'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const Container = styled.div(
  s(
    'flex flex-column bg-gray-200 border-0 border-solid border-b-2 border-gray-500 rounded-lg px-3 py-2'
  ),
  ({ disabled }) =>
    disabled
      ? s('text-gray-700', {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        })
      : s('text-black')
)
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

const charsLimit = 3000

const RichTextField = ({ name, id, disabled, editableStyle, ...fieldProp }) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  const [fieldProps, { touched, error }] = useField({ name, id, ...fieldProp })
  const { value: fieldValue, onChange } = fieldProps

  const value = fieldValue.text || convertFromHtml('<p></p>')
  const charsLeft = charsLimit - (fieldValue.charsCount || 0)

  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

  return (
    <>
      <Container disabled={disabled}>
        <Slate
          editor={editor}
          value={value}
          onChange={(text) => {
            const event = {
              target: {
                value: { text, charsCount: calcCharscount(text), new: true },
                name,
              },
            }
            onChange(event)
          }}
        >
          <Toolbar charsLeft={charsLeft} />
          <Editable
            readOnly={disabled}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter some text…"
            spellCheck
            style={editableStyle}
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
            }}
          />
        </Slate>
      </Container>

      {touched && error && <ErrorMessage>{error.charsCount}</ErrorMessage>}
    </>
  )
}

export { RichTextField }
