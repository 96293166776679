import React from 'react'

import { StatusSelector } from '../../common/StatusSelector'

import { SearchInput } from 'components/common/SearchInput'
import { DateRangePicker } from 'components/common/withTable/DateRangePicker'
import { LISTING_STATUSES } from 'constants/listingStatus'
import { styled, s } from 'lib/styled'

const Root = styled.div(
  s(
    'flex flex-row px-4 py-3 items-center border-0 border-b-1 border-solid border-gray-400'
  ),
  {
    'z-index': 2,
  }
)

const statusOptions = [
  { label: 'All listings', value: '' },
  { label: 'Waiting Approval', value: LISTING_STATUSES.AWAITING_APPROVAL },
  { label: 'Approved listings', value: LISTING_STATUSES.LISTED },
  { label: 'Suspended listings', value: LISTING_STATUSES.SUSPENDED },
  { label: 'Rejected listings', value: LISTING_STATUSES.REJECTED },
]

function Filters({
  filterQuery,
  filterListings,
  filterStatus,
  filterStatusListings,
  dateRange,
  filterDate,
}) {
  return (
    <Root>
      <SearchInput
        value={filterQuery}
        onChange={filterListings}
        placeholder="Search by title"
      />
      <DateRangePicker
        style={s('ml-3')}
        dateRange={dateRange}
        filterDate={filterDate}
      />
      <StatusSelector
        style={s('ml-3')}
        statusOptions={statusOptions}
        value={filterStatus}
        onChange={filterStatusListings}
      />
    </Root>
  )
}

export { Filters }
