import { map, pick } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'
import { debounce } from 'lodash'

import { ProductService } from './service'

import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  sortBy: '',
  sortOrder: '',
  statuses: '',
  limit: 20,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

const read = () => async (id) => ProductService.read(id)

const fetchProducts =
  (module) =>
  async (_, { turnPage = false, turnNext } = {}) => {
    const { filterQuery, paging, statuses, limit, sortBy, sortOrder } =
      module.getState()

    const next = calcNext(turnPage, turnNext, paging, limit)
    const {
      entities,
      order,
      next: newNext,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    } = await ProductService.list({
      query: filterQuery,
      next,
      sortBy,
      sortOrder,
      statuses,
      limit,
    })

    module.setState({
      entities,
      order,
      paging: {
        startRow: calcStartRow(newNext, limit, paging),
        next: newNext,
      },
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    })
  }

const debouncedFetchProducts = (module) => debounce(module.fetchProducts, 350)

const filterStatusProducts = (module) => (statuses) => {
  module.setState({
    statuses,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

  module.debouncedFetchProducts(null, { turnPage: false })
}

const filterProducts = (module) => (query) => {
  module.setState({
    filterQuery: query,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })
  module.debouncedFetchProducts(null, { turnPage: false })
}

const turnPage =
  (module) =>
  ({ turnNext }) => {
    module.fetchProducts(null, { turnPage: true, turnNext })
  }

const inspectProduct = (module) => async (id) => {
  module.setState({
    inspectedEntity: id,
  })
  const product = await ProductService.read(id)

  module.setState({
    entities: { [id]: product },
  })
}

const updateProduct = (module) => async (_, id, updatingProps) => {
  const product = await ProductService.update(id, updatingProps)

  module.setState({
    entities: { [id]: product },
  })
}

const updateProductOptionsVariants = (module) => async (_, id, updatingOptsVars) => {
  const product = await ProductService.updateOptionsVariants(id, updatingOptsVars)

  module.setState({
    entities: { [id]: product },
  })
}

const updateProductMedia =
  (module) =>
  async (_, id, { updateList, createList, deleteList }) => {
    await Promise.all([
      ...map(
        (media) =>
          ProductService.updateMedia(
            id,
            pick(['id', 'tags', 'htmlContent', 'mediaType'], media)
          ),
        updateList
      ),
      ...map(
        (media) =>
          ProductService.createMedia(
            id,
            pick(['mediaType', 'content', 'tags', 'htmlContent'], media)
          ),
        createList
      ),
      ...map(
        (media) => ProductService.deleteMedia(id, pick(['id'], media)),
        deleteList
      ),
    ])

    const Product = await ProductService.read(id)
    module.setState({
      entities: { [id]: Product },
    })
  }

const deleteProduct = (module) => async (id) => {
  let errorOccurred = false
  const status = await ProductService.delete(id)
  if (status !== 200) {
    errorOccurred = true
  } else {
    module.debouncedFetchProducts(null, { turnPage: false })
  }
  return errorOccurred
}

const productModule = createModule({
  name: 'product',
  initialState: INITIAL_STATE,
  decorators: {
    read,
    fetchProducts,
    turnPage,
    debouncedFetchProducts,
    filterProducts,
    filterStatusProducts,
    inspectProduct,
    updateProduct,
    updateProductOptionsVariants,
    updateProductMedia,
    deleteProduct,
  },
})

export { productModule }
