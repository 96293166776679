/* eslint-disable camelcase */
import createAuth0Client from '@auth0/auth0-spa-js'
import { isNil } from '@soltalabs/ramda-extra'

let instance

async function getIdentityClientInstance(options) {
  if (isNil(instance)) {
    await createIdentityClient(options)
  }

  return instance
}

async function createIdentityClient({ domain, clientId, redirectUrl, audience }) {
  instance = await createAuth0Client({
    domain,
    client_id: clientId,
    redirect_uri: redirectUrl,
    audience,
  })
}

export { getIdentityClientInstance }
