import { isNil, isNilOrEmpty, prop } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React from 'react'
import { Redirect } from 'react-router-dom'

import { Invoice } from './Invoice'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { inspectedEntity$, inspectedOrder$, isInspectingOrder$ } from 'modules/order'

const Container = styled.div(
  s('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)

const Scrollable = styled.div(
  s('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const ConnectedOrderDetails = connect(() => ({
  isInspectingOrder: isInspectingOrder$,
  inspectedEntity: inspectedEntity$,
  inspectedOrder: inspectedOrder$,
}))(OrderDetails)

function OrderDetails({ isInspectingOrder, inspectedOrder }) {
  if (isNil(inspectedOrder)) {
    return <Redirect to="/orders" />
  }
  const isLoading =
    isInspectingOrder &&
    !isNilOrEmpty(prop('sessions')(inspectedOrder) || prop('details')(inspectedOrder))
  const {
    reference,
    currency,
    provider,
    createdAt,
    accountDetails,
    subTotal,
    serviceFee,
    taxAmount,
    total,
    sessions,
    details,
  } = inspectedOrder

  return (
    <Container>
      <Loader
        loading={isLoading}
        renderPlaceholder={
          <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
        }
      >
        <Scrollable>
          <Row style={s('my-12 p-6 border-1 border-solid border-grey-lighter')}>
            <Col style={s('flex flex-column flex-1 px-8')}>
              <Invoice
                reference={reference}
                provider={provider}
                createdAt={createdAt}
                accountDetails={accountDetails}
                subTotal={subTotal}
                serviceFee={serviceFee}
                taxAmount={taxAmount}
                total={total}
                sessions={sessions}
                details={details}
                currency={currency}
              />
            </Col>
          </Row>
        </Scrollable>
      </Loader>
    </Container>
  )
}

export { ConnectedOrderDetails as OrderDetails }
