import ky from 'ky'

const baseHttp = ky.create()

const http = baseHttp.extend({
  headers: {
    'content-type': 'application/json',
  },
})

export { http, baseHttp }
