import React from 'react'

import { SearchInput } from 'components/common/SearchInput'
import { StatusSelector } from 'components/common/StatusSelector'
import { PROVIDER_STATUSES } from 'constants/providerStatus'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('flex flex-row px-4 py-3 items-center'), {
  'z-index': 2,
})

const statusOptions = [
  { label: 'All Providers', value: '' },
  { label: 'Active Providers', value: PROVIDER_STATUSES.ACTIVE },
  { label: 'Suspended Providers', value: PROVIDER_STATUSES.SUSPENDED },
  { label: 'Banned Providers', value: PROVIDER_STATUSES.BANNED },
]

function Filters({
  filterQuery,
  filterProviders,
  filterStatus,
  filterStatusProviders,
}) {
  return (
    <Root>
      <SearchInput
        value={filterQuery}
        onChange={filterProviders}
        placeholder="Search by business name"
        containerProps={{ style: s('w-16') }}
      />
      <StatusSelector
        style={s('ml-3')}
        statusOptions={statusOptions}
        value={filterStatus}
        onChange={filterStatusProviders}
      />
    </Root>
  )
}

export { Filters }
