import { isNotEmpty, prop } from '@soltalabs/ramda-extra'
import { useCombobox } from 'downshift'
import { useField } from 'formik'
import React, { useState, useEffect } from 'react'

import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { getIdentityClientInstance } from 'lib/solta-id'
import { styled, s } from 'lib/styled'

const service = http.extend({
  prefixUrl: `${CONFIG.API.URL}/admin`,
  hooks: {
    beforeRequest: [
      async (request) => {
        const identityClient = await getIdentityClientInstance()
        const token = await identityClient.getTokenSilently()
        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const Root = styled.div(s('relative'), ({ renderShadow }) =>
  renderShadow ? s('shadow-sm') : {}
)
const Label = styled.label(
  s('inline-block uppercase tracking-wide text-xs text-gray-600 font-light'),
  ({ labelPosition }) => (labelPosition === 'inline' ? s('pr-2') : s('pb-2'))
)
const InputContainer = styled.div(
  s(
    'w-full bg-gray-200 text-sm text-black border-0 border-b-2 border-solid border-gray-500 rounded-lg px-3 py-2'
  ),
  ({ isAttachedToSuggestions }) =>
    isAttachedToSuggestions ? s('rounded-b-none border-gray-400') : {}
)

const Input = styled.input(
  s('bg-transparent p-0 m-0 w-full border-0'),
  ({ readOnly }) =>
    readOnly ? s('text-gray-700') : { '&::placeholder': s('text-black') }
)

const Suggestions = styled.ul(
  s(
    'absolute z-1 bg-gray-200 p-0 py-1 m-0 w-full border-0 border-b-2 border-solid border-gray-500 rounded-b-lg'
  ),
  {
    listStyleType: 'none',
    overflow: 'hidden',
  },
  ({ isVisible }) => (isVisible ? s('block') : s('hidden'))
)

const Item = styled.li(
  s('px-3 py-2'),
  {
    cursor: 'pointer',
    '&:hover': s('bg-gray-400'),
  },
  ({ isHighlighted }) => (isHighlighted ? s('bg-gray-400') : {})
)

const ErrorMessage = styled.div(s('static mt-2 text-error text-sm'))

const fetchNewProviderAutofills = async (query) => {
  const config = {
    searchParams: {
      q: query,
    },
  }
  return service.get('providerAutofill', config).json()
}

function ProviderSelectField({
  id,
  name,
  label,
  type = 'text',
  value,
  readOnly,
  placeholder,
  ...props
}) {
  const [results, setResults] = useState([])
  const hasResults = isNotEmpty(results)

  const [
    { onChange: onFieldChange, onBlur: onFieldBlur, multiple },
    { touched, error },
    { setTouched: setFieldTouched, setError },
  ] = useField({
    name,
    id,
    type,
    ...props,
  })

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    reset,
  } = useCombobox({
    labelId: name,
    inputId: name || id,
    items: results,
    itemToString: prop('name'),

    onStateChange: ({ type: stateChangeType, inputValue: query }) => {
      const { InputChange, InputBlur } = useCombobox.stateChangeTypes

      const handler = {
        [InputChange]: async () => {
          if (query.length < 3) return
          const newAutoFills = await fetchNewProviderAutofills(query)
          if (newAutoFills?.items) setResults(newAutoFills.items)

          onFieldChange({ target: { value: undefined, name } })
        },
        [InputBlur]: () => setResults([]),
      }[stateChangeType]

      if (handler) {
        handler()
      }
    },

    onSelectedItemChange: async ({ selectedItem }) => {
      try {
        onFieldChange({ target: { value: selectedItem, name } })
      } catch (e) {
        setError(e.message)
      }

      setResults([])
    },
  })

  function handleBlur(...args) {
    setFieldTouched(true)

    onFieldBlur(...args)
  }

  useEffect(() => {
    if (readOnly) {
      reset()
    }
  }, [readOnly])
  return (
    <Root>
      <Label {...getLabelProps()}>{label}</Label>

      <InputContainer isAttachedToSuggestions={hasResults} {...getComboboxProps()}>
        <Input
          multiple={multiple}
          placeholder={placeholder}
          {...getInputProps({ onBlur: handleBlur })}
          {...props}
        />
      </InputContainer>

      {!hasResults && touched && error && <ErrorMessage>{error?._id}</ErrorMessage>}

      <Suggestions isVisible={hasResults} {...getMenuProps()}>
        {results.map((provider, index) => (
          <Item
            isHighlighted={highlightedIndex === index}
            key={prop('place_id', provider)}
            {...getItemProps({ item: provider, index })}
          >
            {prop('name', provider)}
          </Item>
        ))}
      </Suggestions>
    </Root>
  )
}

export { ProviderSelectField }
