import { prop, defaultTo, isNotNil, pipe, pickThenRename } from '@soltalabs/ramda-extra'
import { normalize, schema } from 'normalizr'

import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { getIdentityClientInstance } from 'lib/solta-id'

const service = http.extend({
  prefixUrl: `${CONFIG.API.URL}/admin`,
  hooks: {
    beforeRequest: [
      async (request) => {
        const identityClient = await getIdentityClientInstance()
        const token = await identityClient.getTokenSilently()
        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const listingEntity = new schema.Entity('listings')

const ListingService = {
  async list({ query = '', limit = 10, next, sortBy, sortOrder, statuses, from, to }) {
    const config = {
      authorize: true,
      searchParams: {
        q: query,
        limit,
        sortBy,
        sortOrder,
        statuses,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    if (isNotNil(from)) {
      config.searchParams.from = from
    }

    if (isNotNil(to)) {
      config.searchParams.to = to
    }

    const { items, paging } = await service.get('listings', config).json()

    const normalized = normalize(items, [listingEntity])
    const entities = defaultTo({}, prop('listings', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      sortOrder: paging.sortOrder,
    }
  },

  async read(id) {
    const config = {
      authorize: true,
    }
    return service.get(`listings/${id}`, config).json()
  },

  async approve(id) {
    const config = {
      authorize: true,
    }
    return service.post(`listings/${id}/public`, config).json()
  },

  async suspend(id, reason) {
    const config = {
      authorize: true,
      json: { reason },
    }
    return service.delete(`listings/${id}/public`, config).json()
  },

  async reject(id, reason) {
    const config = {
      authorize: true,
      json: { reason },
    }
    return service.delete(`listings/${id}`, config).json()
  },

  async update(id, body) {
    const payload = pipe(
      pickThenRename([
        'summary',
        'description',
        'categories',
        'keywords',
        'itemsToBring',
        'itemsToWear',
        'genders',
      ])
    )(body)

    const config = {
      authorize: true,
      json: payload,
    }
    return service.patch(`listings/${id}`, config).json()
  },

  async createMedia(id, body) {
    const config = {
      authorize: true,
      json: body,
    }

    return service.post(`listings/${id}/media/`, config).json()
  },

  async updateMedia(id, { id: mediaId, tags, htmlContent, mediaType }) {
    const config = {
      authorize: true,
      json: { tags, htmlContent, mediaType },
    }

    return service.patch(`listings/${id}/media/${mediaId}`, config).json()
  },

  async deleteMedia(id, { id: mediaId }) {
    const config = {
      authorize: true,
    }

    return service.delete(`listings/${id}/media/${mediaId}`, config).json()
  },
}

export { ListingService }
