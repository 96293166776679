/* eslint-disable complexity */
import {
  prop,
  isNotNil,
  partition,
  has,
  isNotEmpty,
  isNil,
} from '@soltalabs/ramda-extra'

import { MEDIA_TYPES } from 'modules/listing'

function findPhotosNeedUpdateOrCreate(initialPhotos) {
  return function (submitPhoto) {
    return (
      initialPhotos.filter(function (initialPhoto) {
        return isNotNil(initialPhoto.content) && initialPhoto.tags === submitPhoto.tags
      }).length === 0
    )
  }
}

function findPhotosNeedDelete(submitPhotos) {
  return function (initialPhoto) {
    return (
      submitPhotos.filter(function (submitPhoto) {
        return submitPhoto.content === initialPhoto.content
      }).length === 0
    )
  }
}

function createDeleteMediaList(media, initialPhotos, initialVideo, initialBrochure) {
  const deleteMediaList = []
  const { photos, video, brochure } = media
  const photosNeedDelete = initialPhotos.filter(findPhotosNeedDelete(photos))

  if (isNotNil(initialVideo) && isNil(prop('content', video))) {
    deleteMediaList.push(initialVideo)
  }

  if (isNotNil(initialBrochure) && isNil(prop('content', brochure))) {
    deleteMediaList.push(initialBrochure)
  }

  return isNotEmpty(photosNeedDelete)
    ? [...deleteMediaList, ...photosNeedDelete]
    : deleteMediaList
}

function createMediaSubmitList(media, initialPhotos, initialVideo, initialBrochure) {
  const createList = []
  const { photos, video, brochure } = media

  const deleteList = createDeleteMediaList(
    media,
    initialPhotos,
    initialVideo,
    initialBrochure
  )

  const photosNeedUpdateOrCreate = photos.filter(
    findPhotosNeedUpdateOrCreate(initialPhotos)
  )

  const [updateList, photosNeedCreate] = partition(
    has('content'),
    photosNeedUpdateOrCreate
  )

  if (isNotEmpty(photosNeedCreate)) {
    createList.push(
      ...photosNeedCreate.map((photo) => ({
        mediaType: MEDIA_TYPES.valueOf(MEDIA_TYPES.PHOTO),
        content: photo.uploadId,
        tags: isNotEmpty(photo.tags) ? photo.tags : undefined,
      }))
    )
  }

  if (isNotNil(prop('uploadId', video))) {
    createList.push({
      mediaType: MEDIA_TYPES.valueOf(MEDIA_TYPES.VIDEO),
      content: video.uploadId,
    })
  }

  if (isNotNil(prop('uploadId', brochure))) {
    createList.push({
      mediaType: MEDIA_TYPES.valueOf(MEDIA_TYPES.BROCHURE),
      content: brochure.uploadId,
    })
  }

  return { updateList, createList, deleteList }
}

export { createMediaSubmitList }
