/* eslint-disable react/jsx-key */
import { prop, path, head, isNil, isNilOrEmpty } from '@soltalabs/ramda-extra'
import { format, addMinutes } from 'date-fns'
import React from 'react'

import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { deepMemo } from 'utils/react'
import { safelyParse } from 'utils/safelyParse'

const Container = styled.section(s('flex flex-1 flex-column text-sm'))
const Header = styled.div(s('flex flex-row pb-10'))
const Label = styled.div(s('text-base italic font-bold my-1 text-black'))
const PriceLabel = styled.div(s('text-base font-bold my-1 text-black'))
const ListingTitle = styled.div(s('text-2xl text-primary italic font-bold'))
const EventDate = styled.div(s('text-base text-indigo-dark italic font-bold'))
const Details = styled.div(s('flex flex-row my-1 text-black'))

const Branding = styled.div(s('flex items-center justify-center border-0 mt-4 w-12'))
const Logo = styled.img(s('w-full h-full'))

const Memoized = deepMemo(Invoice)

const currencyFormatter = (number) => `$${number.toFixed(2)}`

function parseEventDateAndTime(startAt) {
  if (isNil(startAt)) {
    return 'Unspecified Event Date'
  }

  const scheduledDate = format(new Date(startAt), 'eee dd MMM yyyy')
  const startTime = format(new Date(startAt), 'h:mm aaa').toLowerCase()
  const endTime = format(addMinutes(new Date(startAt), 0), 'h:mm aaa').toLowerCase()

  return `${scheduledDate} \xa0\xa0\xa0 ${startTime} to ${endTime}`
}

function Invoice({
  reference,
  provider,
  createdAt,
  accountDetails,
  subTotal,
  taxAmount,
  total,
  sessions,
  details,
  currency,
  serviceFee = 0,
}) {
  const {
    name: providerName,
    address: providerAddress,
    phone: providerPhone,
  } = provider

  const { name: clientName, email: clientEmail, mobile: clientMobile } = accountDetails

  const listingTitle = path(['listing', 'title'])(head(sessions ?? []))
  const productName = path(['product', 'name'], details)
  const productContent = (lineItems) =>
    lineItems.map(({ quantity, unitPrice, optionSet }) => (
      <>
        <Row gutter={[16, 16]} style={s('justify-between')}>
          <Col span={6}>
            <Details>{optionSet.join(' - ')}</Details>
          </Col>

          <Col span={6}>
            <Details style={s('justify-end')}> X{quantity}</Details>
          </Col>

          <Col span={6} style={s('items-end')}>
            <Details style={s('justify-end')}>{currencyFormatter(unitPrice)}</Details>
          </Col>

          <Col span={6} style={s('items-end')}>
            <Details style={s('justify-end font-bold')}>
              {currencyFormatter(unitPrice * quantity)}
            </Details>
          </Col>
        </Row>
      </>
    ))

  const listingContent = (sessions) =>
    sessions.map(({ lineItems, event }) => (
      <>
        <Row gutter={[16, 16]} style={s('my-3')}>
          <Col>
            <EventDate>{parseEventDateAndTime(prop('startAt')(event))}</EventDate>
          </Col>
        </Row>

        {lineItems.map(({ item, requiredSpots, quantity, unitPrice }) => (
          <Row gutter={[16, 16]} style={s('justify-between')}>
            <Col span={6}>
              <Details>
                {item} ({requiredSpots / quantity}
                {requiredSpots / quantity === 1 ? ' spot' : ' spots'})
              </Details>
            </Col>

            <Col span={6}>
              <Details style={s('justify-end')}> X{quantity}</Details>
            </Col>

            <Col span={6} style={s('items-end')}>
              <Details style={s('justify-end')}>{currencyFormatter(unitPrice)}</Details>
            </Col>

            <Col span={6} style={s('items-end')}>
              <Details style={s('justify-end font-bold')}>
                {currencyFormatter(unitPrice * quantity)}
              </Details>
            </Col>
          </Row>
        ))}
      </>
    ))
  const invoiceContent = isNilOrEmpty(sessions)
    ? productContent(details.lineItems)
    : listingContent(sessions)

  return (
    <Container>
      <Header style={s('justify-between')}>
        <Branding>
          <Logo src="/logo.png" />
        </Branding>

        <Col style={s('flex flex-column self-center items-end')}>
          <Details>Booking Number: {reference}</Details>

          <Details>Created: {safelyParse(createdAt)}</Details>
        </Col>
      </Header>

      <Row gutter={[16, 32]} style={s('justify-between items-center')}>
        <Col style={s('flex flex-column')}>
          <Label>Provider:</Label>

          <Details>{providerName}</Details>

          <Details>{prop('formattedAddress', providerAddress)}</Details>

          <Details>{providerPhone}</Details>
        </Col>

        <Col style={s('flex flex-column items-end')}>
          <Label>Client:</Label>

          <Details>{clientName}</Details>

          <Details>{clientEmail}</Details>

          <Details>{clientMobile}</Details>
        </Col>
      </Row>

      <Row gutter={[16, 32]}>
        <Col>
          <Label>Payment Method:</Label>

          <Details>card: xxxx xxxx xxxx xxxx</Details>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={s('mt-1')}>
        <Col>
          <ListingTitle>{listingTitle ?? productName}</ListingTitle>
        </Col>
      </Row>

      {invoiceContent}

      <Row gutter={[16, 32]} style={s('justify-end mt-2 pb-6')}>
        <Col
          style={s(
            'w-16 flex flex-column border-0 border-t-1 border-solid border-black'
          )}
        >
          <Row style={s('justify-between flex-1')}>
            <Col>
              <Details>subTotal:</Details>
            </Col>

            <Col>
              <PriceLabel>{currencyFormatter(subTotal)}</PriceLabel>
            </Col>
          </Row>

          <Row style={s('justify-between flex-1')}>
            <Col>
              <Details>GST:</Details>
            </Col>

            <Col>
              <PriceLabel>{currencyFormatter(taxAmount)}</PriceLabel>
            </Col>
          </Row>

          <Row style={s('justify-between flex-1')}>
            <Col>
              <Details>Service Fee:</Details>
            </Col>

            <Col>
              <PriceLabel>{currencyFormatter(serviceFee ?? 0)}</PriceLabel>
            </Col>
          </Row>

          <Row style={s('justify-between flex-1 mt-2')}>
            <Col>
              <PriceLabel style={s('text-xl')}>Total:</PriceLabel>
            </Col>

            <Col>
              <PriceLabel style={s('text-xl text-primary-dark')}>
                {currency.toUpperCase()} {currencyFormatter(total)}
              </PriceLabel>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export { Memoized as Invoice }
