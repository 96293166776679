import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { getIdentityClientInstance } from 'lib/solta-id'
import { createAddressObject } from 'utils/createAddressObject'

const service = http.extend({
  prefixUrl: `${CONFIG.API.URL}/admin`,
  hooks: {
    beforeRequest: [
      async (request) => {
        const identityClient = await getIdentityClientInstance()
        const token = await identityClient.getTokenSilently()
        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const ProductDraftService = {
  async create(data) {
    const dataToSend = { ...data }
    if (data?.location !== undefined) {
      dataToSend.address = createAddressObject(data.location)
      dataToSend.location = {
        type: 'Point',
        coordinates: [
          data?.location?.geometry?.location?.lng(),
          data?.location?.geometry?.location?.lat(),
        ],
      }
    }
    const config = { json: dataToSend }
    return service.post('products', config).json()
  },
}

export { ProductDraftService }
