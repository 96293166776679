import { merge } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import React, { useState } from 'react'

import { StyledToggleSwitch as ToggleSwitch } from './ToggleSwitch'

import { Button } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { PROVIDER_STATUSES } from 'constants/providerStatus'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { getIsUpdatingProvider$ } from 'modules/provider'

const Root = styled.section(
  s('p-4 border-0 border-b-1 border-solid border-grey-lighter my-4')
)
const Title = styled.div(s('text-black text-xl font-normal'))
const Text = styled.div(s('uppercase text-sm text-black'))

const EditButton = styled(Button)(s('w-5'))
const Textarea = styled.textarea(
  s(
    'mt-4 flex-1 flex flex-column bg-grey-lighter border-white border-0 text-sm text-black rounded-lg px-3 py-2'
  ),
  {
    opacity: '0.6',
  }
)

const statusMessageMapper = {
  [PROVIDER_STATUSES.ACTIVE]: 'Active account: have full access to the account',
  [PROVIDER_STATUSES.SUSPENDED]:
    'Suspend status: allow provider to login to portal but not be able to create new listing. Also, all current active listings will not be available on the market.',
  [PROVIDER_STATUSES.BANNED]:
    'Banned account: provider will not allow to login to the admin portal. Also, all active listings will be taken down from the market.',
}

const statusMapper = {
  [PROVIDER_STATUSES.ACTIVE]: 0,
  [PROVIDER_STATUSES.SUSPENDED]: 1,
  [PROVIDER_STATUSES.BANNED]: 2,
}

const ConnectedPersonalDetails = connect(({ id }) => ({
  isUpdatingProvider: getIsUpdatingProvider$(id),
}))(ManageAccount)

// eslint-disable-next-line complexity
function ManageAccount({
  isUpdatingProvider,
  status,
  listingApprovalRequired,
  onEdit,
  onSave,
  isEditing,
}) {
  const [editedToggles, setEditedToggles] = useState({
    status,
    listingApprovalRequired,
  })

  function handleToggle(value) {
    setEditedToggles((prevState) => merge(prevState, value))
  }

  return (
    <Root>
      <Row gutter={[16, 24]} style={s('justify-between')}>
        <Col>
          <Title>Manage Account</Title>
        </Col>

        <Col>
          <Row>
            {isUpdatingProvider && (
              <Spinner style={s('mr-2')} size={10} thickness={3} color="#FFFFFF" />
            )}

            {isEditing ? (
              <div>
                <EditButton onClick={() => onSave({ editedToggles })}>Save</EditButton>
              </div>
            ) : (
              <EditButton onClick={onEdit}>Edit</EditButton>
            )}
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={3} />

        <Col span={21}>
          <Row gutter={[16, 16]} style={s('justify-end')} />

          <Row style={s('justify-between items-center')}>
            <Col>
              <Text>Require &apos;Activity Approval&apos;</Text>
            </Col>

            <Col span={12}>
              <ToggleSwitch
                disabled={!isEditing}
                selectedOption={listingApprovalRequired ? 1 : 0}
                toggleType={'approve'}
                onChange={handleToggle}
              />
            </Col>
          </Row>

          <Row style={s('justify-between items-center')}>
            <Col>
              <Text>Provider Account Status</Text>
            </Col>

            <Col span={12}>
              <ToggleSwitch
                disabled={!isEditing}
                selectedOption={statusMapper[status]}
                toggleType={'status'}
                onChange={handleToggle}
              />
            </Col>
          </Row>

          <Row style={s('items-center')}>
            <Textarea
              disabled
              type="text"
              rows="2"
              cols="33"
              wrap="hard"
              value={statusMessageMapper[editedToggles.status]}
            />
          </Row>
        </Col>
      </Row>
    </Root>
  )
}

export { ConnectedPersonalDetails as ManageAccount }
